import {
    SET_BALANCE_LIMIT
} from '../constants/ActionTypes';

const INIT_STATE = {
    balanceLimit:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_BALANCE_LIMIT: {
            return {...state,balanceLimit:action.payload}
        }
        default:
            return state;
    }
}

import {
    SET_GAME_WOF_DATA,
    CHANGE_WOF_START,
    SET_WOF_BET_PLACE,
    SET_LOADER_WOF
} from '../../constants/ActionTypes'

const INIT_STATE = {
    gameData: {},
    lastGameData: [],
    gameBetLoader:false,
    setBetPlace: false,
    readyStart: 'pending'
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_GAME_WOF_DATA: {
            return {...state, gameData: action.gameData,lastGameData: action.lastGameData,readyStart:'start'}
        }
        case SET_LOADER_WOF: {
            return {...state, gameBetLoader: action.payload}
        }
        case CHANGE_WOF_START: {
            return {...state, readyStart: 'pending',gameData: {},lastGameData: []}
        }
        case SET_WOF_BET_PLACE: {
            return {...state, setBetPlace: action.payload}
        }
        default:
            return state;
    }
}

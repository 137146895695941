import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";

import {
    SET_PREMATCHES,
    SET_EVENT_LIST,
    SET_MARKET_LIST,
    PM_SHOW_LOADER,
    SET_MARKET_COLLECTIONS,
} from '../constants/ActionTypes';

export function getPreMatches(){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setPreMatches())
        }else {
            axios({
                url: `${API_URL}/event/getPrematch`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {
                    query:
                        `mutation {
                        sportList(sportListInput:{rangeFilter:""}) {
                            sportId
                            sportName
                            countries {
                                name
                                leagues {
                                    competitionId
                                    competitionName
                                    eventsCount
                                }
                            }
                        }
                    }
                `
                }
            }).then(sportTypes => {
                if (!sportTypes.data.data.error && sportTypes.data.data.sportList){
                    dispatch(setPreMatches(sportTypes.data.data.sportList))
                } else {
                    dispatch(setPreMatches())
                }
            }).catch(e =>{
                console.log('getPreMatches',e);
                dispatch(setPreMatches())
            })
        }
    }
}

export function getEventList(queries=false) {
    return dispatch => {

        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        let { sportId,country,competitionName } = queries;

        if (!sportId || !competitionName || !country || !user_token){
            dispatch(setEventList([],false))
        } else {
            axios({
                url: `${API_URL}/event/getPrematch`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {
                    query: `
                mutation {
                    eventList(eventListInput: {
                      sportId: ${Number(sportId)}
                      topEvents: false
                      markets: ["1x2"]
                      country: "${country}"
                      competitionName: "${competitionName}"
                  }) {
                      sportId
                      sportName
                      priority
                      visible
                      competitions {
                        competitionId
                        country
                        competitionName
                        events {
                          eventId
                          eventName
                          shortCode
                          eventStartTime
                          top
                          pricesCount
                          champRefId
                          team1RefId
                          team2RefId
                          collections {
                            collectionId
                            collectionName
                            markets {
                                marketId
                                marketName
                                marketCode
                                prices {
                                  referenceId
                                  priceName
                                  handicapValue
                                  rate
                                  createdDate
                                }
                             }
                          }
                        }
                      }
                    }
                  }
            `
                }
            }).then(events => {
                if(!events.data.error){
                    dispatch(setEventList(events.data.data.eventList,queries))
                }else {
                    dispatch(setEventList())
                }
            }).catch((e) =>{
                dispatch(setEventList())
            })
        }
    }
}

export function getMarketList(eventId=false,queries=false) {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        dispatch(showHidePmLoader(true));

        let {sportId,country,competitionName} = queries;

        if (!sportId || !country || !competitionName || !eventId || !user_token){
            dispatch(setMarketList())
        }

        axios({
            url: `${API_URL}/event/getPrematch`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'authorization':`${user_token}`
            },
            data: {
                query: `
                mutation {
                    eventList(eventListInput: {
                      sportId: ${Number(sportId)}
                      topEvents: false
                      country: "${country}"
                      competitionName: "${competitionName}"
                      eventId:${Number(eventId)}
                  }) {
                      sportId
                      sportName
                      priority
                      visible
                      competitions {
                        competitionId
                        country
                        competitionName
                        events {
                          eventId
                          eventName
                          shortCode
                          eventStartTime
                          top
                          pricesCount
                          champRefId
                          team1RefId
                          team2RefId
                          collections {
                            collectionId
                            collectionName
                            markets {
                                marketId
                                marketName
                                marketCode
                                prices {
                                  referenceId
                                  priceName
                                  handicapValue
                                  rate
                                  createdDate
                                }
                             }
                          }
                        }
                      }
                    }
                  }
            `
            }
        }).then(events => {
            if(!events.data.error){
                dispatch(setMarketList(eventId,events.data.data.eventList));
                dispatch(showHidePmLoader(false));
            }else {
                dispatch(setMarketList());
                dispatch(showHidePmLoader(false));

            }
        }).catch(() =>{
            dispatch(setMarketList());
            dispatch(showHidePmLoader(false));
        })
    }
}

export function getMarketCollections() {
    return dispatch => {
  
        return new Promise(resolve => {
  
            let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
            if (!user_token) {
                console.log('token Error');
                resolve();
            } else {
                axios({
                    url: `${API_URL}/event/getMarketCollections`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${user_token}`
                    },
                }).then(result => {
                    dispatch(setMarketCollections(result.data.data));
                    resolve();
                }).catch(e => {
                    console.log('Error',e);
                    resolve();
                })
            }
  
        });
  
    }
}

export function setPreMatches(preMatches=[]) {
    return {
        type: SET_PREMATCHES,
        payload: preMatches
    };
}

export function setEventList(eventList=[],queries=false) {
    return {
        type: SET_EVENT_LIST,
        payload: {eventList,queries}
    };
}

export function setMarketList(eventId=false,marketList=[]) {
    return {
        type: SET_MARKET_LIST,
        payload: {
            eventId,
            marketList
        }
    };
}

export function setMarketCollections(data) {
    return {
        type: SET_MARKET_COLLECTIONS,
        value: data
    }
}

export function showHidePmLoader(loader) {
    return {
        type: PM_SHOW_LOADER,
        payload:loader
    };
}



import appLocaleData from 'react-intl/locale-data/zh';
import cnMessages from '../locales/zh';

const zhLan = {
  messages: {
    ...cnMessages
  },
  locale: 'zh',
  data: appLocaleData
};
export default zhLan;

import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  changePassword,
  hideMessage
} from "../actions";
import {bindActionCreators} from "redux";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';




class ChangePassword extends Component {
    constructor() {
        super();

        this.state = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
          errorMessage:'',
        };

        this.handleUserData = this.handleUserData.bind(this);
      }

      componentDidUpdate() {
        const {showMessage,hideMessage} = this.props;
         if (showMessage) {
          setTimeout(() => {
            hideMessage();
          }, 100);
        }
      }
   

    handleDataChange(key,event) {
      this.setState({
        [key]: event.target.value,
      });
  
      if (event && event.key === 'Enter') {
        this.handleUserData();
      }
    }

    handleUserData() {
      let { oldPassword, newPassword, confirmPassword } = this.state;
  
      this.props.changePassword({oldPassword, newPassword, confirmPassword});
    }

    render() {
      const {showMessage, loader, alertMessage, authLoader, countrySettings} = this.props;

        return (
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          {!loader && <div className="app-login-main-content">
  
            <div className="app-logo-content d-flex align-items-center justify-content-center">
              {countrySettings && countrySettings.logo ? <img style={{width:"100%"}} src={countrySettings.logo.signIn} alt={countrySettings.logo.alt} title={countrySettings.logo.title}/> : <div />}
            </div>
  
            <div className="app-login-content">
              <div className="app-login-form">
                <form>
                  <fieldset>
                    <h1 style={{marginTop:'40px'}}><IntlMessages id="PLEASE CHANGE YOUR PASSWORD"/></h1>
                    <TextField
                        type="password"
                        label={<IntlMessages id="Old Password"/>}
                        fullWidth
                        onChange={(e)=> this.handleDataChange('oldPassword',e)}
                        onKeyPress={(e)=> this.handleDataChange('oldPassword',e)}
                        defaultValue=''
                        tabIndex={2}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <TextField
                        type="password"
                        label={<IntlMessages id="New Password"/>}
                        fullWidth
                        onChange={(e)=> this.handleDataChange('newPassword',e)}
                        onKeyPress={(e)=> this.handleDataChange('newPassword',e)}
                        defaultValue=''
                        tabIndex={2}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <TextField
                        type="password"
                        label={<IntlMessages id="Confirm Password"/>}
                        fullWidth
                        onChange={(e)=> this.handleDataChange('confirmPassword',e)}
                        onKeyPress={(e)=> this.handleDataChange('confirmPassword',e)}
                        defaultValue=''
                        tabIndex={2}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button disabled={authLoader} tabIndex={3} onClick={this.handleUserData} variant="contained" color="primary"><IntlMessages id="Change password"/></Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
  
          </div>}
          {loader && <div className="loader-view"><CircularProgress/></div>}
          {showMessage && NotificationManager.error(alertMessage)}
          <NotificationContainer/>
        </div>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
      changePassword,
      hideMessage
    }, dispatch);
}

const mapStateToProps = ({authReducer, settingsReducer}) => {
  const {loader, alertMessage, showMessage, authUser, authLoader} = authReducer;
  const {countrySettings, globalLoader,globalSettings} = settingsReducer;
  return {loader, alertMessage, showMessage, authUser, authLoader, countrySettings, globalLoader,globalSettings}
};

export default connect(mapStateToProps,matchDispatchToProps)(ChangePassword);

import {
    SET_BET_SLIP,
    REMOVE_BET_SLIP,
    EMPTY_BET_SLIP,
    FAILED_BET_SLIP,
    BET_SLIP_LOADER,
    BET_PLACE_LOADER,
    SET_TICKET,
    SET_BET_SLIP_SHOW,
    SET_BET_SLIP_HIDE
} from '../constants/ActionTypes';

const INIT_STATE = {
    betSlipData:{
        bets:[]
    },
    betLoader:false,
    betPlaceLoader: false,
    ticketShow:false,
    betSlipShower:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_BET_SLIP: {
            return {...state, betSlipData:{...action.payload, bets:action.payload.bets} };
        }
        case REMOVE_BET_SLIP:{
            return {...state, betSlipData:{...action.payload,bets: state.betSlipData.bets.filter(b => b.reference_id !== action.payload.remove_reference_id)} }
        }
        case EMPTY_BET_SLIP:{
            return { ...state, betSlipData:{bets:[]} }
        }
        case FAILED_BET_SLIP:{
            return {...state, betSlipData:{bets:[]} }
        }
        case BET_SLIP_LOADER:{
            return {...state, betLoader: action.payload}
        }
        case BET_PLACE_LOADER:{
            return {...state, betPlaceLoader: action.payload}
        }
        case SET_TICKET:{
            return {...state, ticketShow: action.payload}
        }
        case SET_BET_SLIP_SHOW:{
            return {...state, betSlipShower: action.bool}
        }
        case SET_BET_SLIP_HIDE:{
            return {...state, betSlipShower: false}
        }
        default:
            return state;
    }
}

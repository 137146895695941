import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";

import {
    GET_DOWNLOADS,
    GET_STATISTICS_DATA,
    WEBSITE_USER_INFO,
    WEBSITE_WITHDRAW,
    WEBSITE_TICKET_OFFLINE,
    SET_BETS_DATA,
    SET_KASSAS_DATA,
    SET_PAYOUTS_DATA,
    SET_BET_INFO_DATA,
    SET_GAME_ZONE_USER,
    SET_GAME_ZONES
} from '../constants/ActionTypes';

import { setSlip, emptyBetSlip } from '../actions'

export function downloadsData(data=[]) {
    return {
        type: GET_DOWNLOADS,
        payload: data
    };
}

export function resultsData(data=[]) {
    return {
        type: GET_STATISTICS_DATA,
        payload: data
    };
}

export function userInfoSuccess(data={}) {
    return {
        type: WEBSITE_USER_INFO,
        payload: data
    };
}

export function withdrawInfoSuccess(data={}) {
    return {
        type: WEBSITE_WITHDRAW,
        payload: data
    };
}

export function offlineInfoSuccess(data={}) {
    return {
        type: WEBSITE_TICKET_OFFLINE,
        payload: data
    };
}

export function setBetsData(data={},type='bet') {
    return {
        type: type === 'bet' ? SET_BETS_DATA : SET_PAYOUTS_DATA,
        payload: data
    };
}

export function setKassasData(data=[]) {
    return {
        type: SET_KASSAS_DATA,
        payload: data
    };
}

export function setBetPrintData(data={}) {
    return {
        type: SET_BET_INFO_DATA,
        payload: data
    };
}

export function getDownloadsData(){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(downloadsData())
        }else {
            axios({
                url: `${API_URL}/other/getDownloads`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(dataDownload => {
                if (!dataDownload.data.error){
                    dispatch(downloadsData(dataDownload.data.data))
                } else {
                    dispatch(downloadsData())
                }
            }).catch(e =>{
                dispatch(downloadsData())
            })
        }
    }
}

export function getResultsData(options){
    return dispatch => {
        dispatch(resultsData());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(resultsData())
        }else {
            axios({
                url: `${API_URL}/other/getStatistics`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: options
            }).then(dataStatistics => {
                if (!dataStatistics.data.error){
                    dispatch(resultsData(dataStatistics.data.data))
                } else {
                    dispatch(resultsData())
                }
            }).catch(e =>{
                dispatch(resultsData())
            })
        }
    }
}

export function getUserInfoWeb(options){
    return dispatch => {
        dispatch(userInfoSuccess());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(userInfoSuccess(false))
        }else {
            axios({
                url: `${API_URL}/web/userInfo`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: options
            }).then(dataStatistics => {
                if (!dataStatistics.data.error && dataStatistics.data.data && Object.keys(dataStatistics.data.data).length){
                    dispatch(userInfoSuccess(dataStatistics.data.data))
                } else {
                    dispatch(userInfoSuccess(false))
                }
            }).catch(e =>{
                dispatch(userInfoSuccess(false))
            })
        }
    }
}

export function getWithdrawInfoWeb(options){
    return dispatch => {
        dispatch(withdrawInfoSuccess());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(withdrawInfoSuccess(false))
        }else {
            axios({
                url: `${API_URL}/web/transactionInfo`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: options
            }).then(dataStatistics => {
                if (!dataStatistics.data.error && dataStatistics.data.data && Object.keys(dataStatistics.data.data).length){
                    dispatch(withdrawInfoSuccess(dataStatistics.data.data))
                } else {
                    dispatch(withdrawInfoSuccess(false))
                }
            }).catch(e =>{
                dispatch(withdrawInfoSuccess(false))
            })
        }
    }
}

export function getOfflineInfoWeb(options){
    return dispatch => {
        dispatch(offlineInfoSuccess());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(offlineInfoSuccess())
        }else {
            axios({
                url: `${API_URL}/web/searchBet`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: options
            }).then(dataSlip => {
                if (!dataSlip.data.error){
                    // dispatch(offlineInfoSuccess(dataSlip.data.data));
                    dispatch(emptyBetSlip());

                    let slip = {
                        bonus: 0,
                        amount:dataSlip.data.data.amount || 0,
                        bets:[],
                        comp_bonus: 0,
                        evenValue: null,
                        possible_winning: 0,
                        total_amount: 0,
                        total_odds: dataSlip.data.data.total_odds,
                        type: dataSlip.data.data.type,
                        isFastBet: dataSlip.data.data.isFastBet,
                        bonusType: dataSlip.data.data.bonusType,
                    };

                    dataSlip.data.data.bets.forEach((bet)=>{
                        slip.bets.push({
                            amount:bet.amount || 0,
                            errors:[],
                            event_name:bet.event_name,
                            short_code:bet.event_short_code,
                            start_time:bet.event_start_time,
                            isLive:bet.is_live,
                            market_name:bet.market_name,
                            price_hcap:bet.price_hcap,
                            price_name:bet.price_name,
                            price_rate:bet.price_rate,
                            reference_id:bet.reference_id,
                        })
                    });

                    dispatch(setSlip(slip,true))
                } else {
                    dispatch(offlineInfoSuccess())
                }
            }).catch(e =>{
                dispatch(offlineInfoSuccess())
            })
        }
    }
}

export function getBetsData(data,type){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setBetsData());
        }else {
            dispatch(setBetsData());
            axios({
                url: `${API_URL}/other/getStatistics`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data
            }).then(dataStatistics => {
                if (!dataStatistics.data.error){
                    dispatch(setBetsData(dataStatistics.data.data,type))
                } else {
                    dispatch(setBetsData())
                }
            }).catch(e =>{
                dispatch(setBetsData())
            })
        }
    }
}

export function getKassasData(){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setKassasData())
        }else {
            axios({
                url: `${API_URL}/other/getKassa`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(dataStatistics => {
                if (!dataStatistics.data.error){
                    dispatch(setKassasData(dataStatistics.data.data))
                } else {
                    dispatch(setKassasData())
                }
            }).catch(e =>{
                dispatch(setKassasData())
            })
        }
    }
}

export function getBetPrintInfo(data){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        dispatch(setBetPrintData());

        if(!user_token){
            dispatch(setBetPrintData())
        }else {
            axios({
                url: `${API_URL}/other/getBetPrintInfo`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data
            }).then(dataStatistics => {
                if (!dataStatistics.data.error){
                    dispatch(setBetPrintData(dataStatistics.data.data))
                } else {
                    dispatch(setBetPrintData())
                }
            }).catch(e =>{
                dispatch(setBetPrintData())
            })
        }
    }
}

export function getGameZones(){
    return dispatch => {
        dispatch(setGameZones());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(user_token){
            axios({
                url: `${API_URL}/web/getGameZones`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(resultData => {
                if (!resultData.data.error && resultData.data.data){
                    dispatch(setGameZones(resultData.data.data))
                } else {
                    dispatch(setGameZones())
                }
            }).catch(e =>{
                dispatch(setGameZones())
            })
        }
    }
}

export function getGameZoneUser(userId){
    return dispatch => {
        dispatch(setGameZoneUser());
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(user_token){
            axios({
                url: `${API_URL}/web/getGameZoneInfo`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {user_id:userId}
            }).then(resultData => {
                if (!resultData.data.error && resultData.data.data){
                    dispatch(setGameZoneUser(resultData.data.data[0]))
                } else {
                    dispatch(setGameZoneUser())
                }
            }).catch(e =>{
                dispatch(setGameZoneUser())
            })
        }
    }
}

export function setGameZones(data=[]) {
    return {
        type: SET_GAME_ZONES,
        payload: data
    };
}

export function setGameZoneUser(data={}) {
    return {
        type: SET_GAME_ZONE_USER,
        payload: data
    };
}

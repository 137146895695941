import enLang from './entries/en';
import cnLang from './entries/zh';
import swLang from './entries/sw';
import frLang from './entries/fr';
import {addLocaleData} from 'react-intl';

const AppLocale = {
  en: enLang,
  zh: cnLang,
  sw: swLang,
  fr: frLang
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.sw.data);
addLocaleData(AppLocale.fr.data);

export default AppLocale;

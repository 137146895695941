import axios from 'axios';
import {API_URL} from '../util/configs';
import cookie from "react-cookies";

import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  PM_USER_SIGN_IN_SUCCESS,
  PM_USER_INFO,
  PM_AUTH_LOADER,
  PM_USER_SIGN_IN_FAIL,
  PM_USER_EMPLOYEE,
  PM_USER_EMPLOYEE_SUCCESS,
  CLEAR_EMPLOYEE_MESSAGE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from '../constants/ActionTypes';

import {getBalanceLimit} from "./BalanceLimit";


export const pmUserSignIn = (user) => {
    return dispatch => {
    if (!user.username || !user.password){
      dispatch(pmUserSignInFail())
    } else {
      axios.post(`${API_URL}/auth/signIn`, user, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response)=>{
        if (response.data.error){
          dispatch(pmUserSignInFail(response.data.message))
        }else {
          cookie.save("pm-token",response.data.data.user_token);
          dispatch(pmUserSignInSuccess(response.data.data));
          dispatch(getBalanceLimit());
        }
      }).catch((e)=>{
          let message = e ? e.response.data.message : '';
          dispatch(pmUserSignInFail(message))
      })
    }
  }
};

export const pmUserSignInSuccess = (data) => {
  return {
    type: PM_USER_SIGN_IN_SUCCESS,
    payload: data
  };
};

export const pmUserSignInFail = (message) => {
  return {
    type: PM_USER_SIGN_IN_FAIL,
    payload:message
  };
};

export const pmUserSignOut = () => {
    return dispatch => {
        let token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null;

        axios.post(`${API_URL}/auth/signOut`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'authorization':`${token}`
            }
        }).then((response)=>{
            cookie.remove('pm-token',{path:'/'});
            cookie.remove('pm-token');
            dispatch({type:PM_USER_SIGN_IN_SUCCESS});
        }).catch((e)=>{
            console.log('sign ot Error', e);
            dispatch({type:PM_USER_SIGN_IN_SUCCESS})
        })
        }
};

export const pmUserInfo = () => {
  return dispatch => {
    dispatch(showAuthLoader());

    let token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null;

    if(token !== null){
      axios.post(`${API_URL}/account/getInfo`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'authorization':`${token}`
        }
      }).then((response)=>{
          dispatch({
              type: PM_USER_INFO,
              payload:response.data.data
          });
      }).catch((e)=>{
          if(e.response.data.code){
              cookie.remove('pm-token',{path:'/'});
              cookie.remove('pm-token');
          }
          dispatch({
              type: PM_USER_INFO,
              payload: null
          });
      })
    } else {
      dispatch({
        type: PM_USER_INFO,
        payload: null
      });
    }
  };
};

export const pmUserEmployee = (data) => {
    return dispatch => {
        let token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null;

        if (!token){
            dispatch(userEmployee())
        } else {
            axios.post(`${API_URL}/auth/signInEmployee`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${token}`
                }
            }).then((response)=>{
                dispatch(pmUserEmployeeSuccess(false));
                dispatch(userEmployee(response.data.data));
                dispatch(getBalanceLimit());
            }).catch((e)=>{
                dispatch(userEmployee([],e.response.data.message));
                dispatch(pmUserInfo())
            })
        }
    }
};

export const pmUserEmployeeSuccess = (data) => {
    return {
        type: PM_USER_EMPLOYEE_SUCCESS,
        payload:data
    };
};

export const pmUserEmployeeSignOut = () => {
    return dispatch => {
        let token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null;

        if (!token){
            dispatch(userEmployee())
        } else {
            axios.post(`${API_URL}/auth/signOutEmployee`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${token}`
                }
            }).then((response)=>{
                dispatch(userEmployee());
                dispatch(pmUserInfo())
            }).catch((e)=>{
                console.log('sign ot Error', e);
                dispatch(userEmployee());
                dispatch(pmUserInfo());
            })
        }

    }
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: PM_AUTH_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const userEmployee = (employeeData=[],message='') => {
    return {
        type:PM_USER_EMPLOYEE,
        payload:{
            employeeData,
            message
        },
    }
};

export const clearEmployeeMessage = () => {
  return dispatch => dispatch({type: CLEAR_EMPLOYEE_MESSAGE})
};

export const changePassword = (user) => {
  return dispatch => {
    let token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null;
    const {oldPassword, newPassword, confirmPassword} = user;
    if(newPassword !== confirmPassword) return dispatch(changePasswordFail('Passwords are not same!'))
    if(newPassword.length < 8) return dispatch(changePasswordFail('Password length must be greater than 8 characters!'))
    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d#$@!%&*_?.])[A-Za-z\d#$@!%&*?._]{8}/.test(newPassword)) return dispatch(changePasswordFail('The password must contain at least one uppercase and one lowercase letter and a number.'))

    if (!oldPassword || !newPassword || !confirmPassword){
      dispatch(changePasswordFail('Complete all fields'))
    } else {
      axios.post(`${API_URL}/account/changePassword`, user, {
        headers: {
          'Content-Type': 'application/json',
          'authorization':`${token}`
        }
      }).then((response)=>{
        if (response.data.error){
          dispatch(changePasswordFail(response.data.message))
        }else {
          dispatch(changePasswordSuccess(response.data.data));
        }
      }).catch((e)=>{
          let message = e ? e.response.data.message : '';
          dispatch(changePasswordFail(message))
      })
    }
  }
};

export const changePasswordSuccess = (data) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data
  };
};

export const changePasswordFail = (message) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload:message
  };
};
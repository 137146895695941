import { createStore, applyMiddleware,compose } from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import ReduxThunk from 'redux-thunk';

import reducers from '../reducers/index';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = routeMiddleware;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers(history),composeEnhancers(applyMiddleware(middlewares,ReduxThunk)));
// const store = createStore(reducers(history),compose(applyMiddleware(middlewares,ReduxThunk)));

export const { dispatch } = store;
export default store;

export {history};
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import BetCalculation from "./Calculation";

import IntlMessages from 'util/IntlMessages';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  MINI_DRAWER
} from '../../constants/ActionTypes'
import CircularProgress from "../../components/CircularProgress";
import { fixByRule } from '../../util/index';

import { changeNavigationStyle, setDrawerType, setHorizontalMenuPosition, removeSlip, emptySlip,setSlip,getMaxBet,setBetSlip } from '../../actions';

class Customizer extends React.Component {
  constructor(props){
    super(props);

    this.state = {
        betSlipData: {},
        isBetBtnActive: true,
        maxBetLoader: {},
    };

    this.handleBetPlace = this.handleBetPlace.bind(this);
  }

  messagesEndRef = React.createRef();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevprops,prevState) {
    if (prevState.betSlipData.bets.length < this.state.betSlipData.bets.length) {
      this.scrollToBottom();
    }
  }

    scrollToBottom = () => {
        if(this.messagesEndRef && this.messagesEndRef.current){
            this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

  validate = (evt) => {
    let theEvent = evt || window.event;
    let key;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }

    let regex = /[0-9]|\./;

    if(!regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
    }

    if (evt.key === "Enter") {
        this.handleBetPlace();
    }
  };

  calculate = (event,key = 'multy') => {
      let slip = this.state.betSlipData;
      const { globalSettings, authUser } = this.props;
      if (['VAMOSETH','HABESHAETH'].includes(globalSettings.label.project)) {
          const amount = Number(event.target.value);
          if (Number(amount.toFixed()) !== amount) {
              return;
          }
      }
      if(key === 'multy'){
          slip.amount = event.target.value;
      }else {
          slip.bets[key].amount = event.target.value;
      }

      const params = {
          country: {
            'BonusType': this.props.globalSettings.betting.BonusType,
            'Tax': this.props.globalSettings.betting.Tax,
            'MaxWinSum': this.props.globalSettings.betting.MaxWinSum,
            'CompensationDeduction': this.props.globalSettings.betting.CompensationDeduction
          },
          configs:{
            'multyBonusRulerCB':this.props.globalSettings.betting.MultyBonusRuler,
            'maxOddGlobal':this.props.globalSettings.betting.maxOddGlobal,
            'project':this.props.globalSettings.label.project,
            'betAmountVatTax':this.props.globalSettings.betting.betAmountVatTax,
            'fixedPercentBonus': this.props.globalSettings.betting.fixedPercentBonus,
          },
          authUser,
      };
      if ((slip.amount && !slip.amount.length) || slip.amount === '0') {
          slip.possible_tax = 0;
          slip.winning_with_tax = 0;
      }
      slip = BetCalculation.sport(params, slip);
      slip.errors = [];
      this.props.setBetSlip(slip);
  };

  setFixedDrawer(){
    this.props.setDrawerType(FIXED_DRAWER);
  };

  setCollapsedDrawer(){
    this.props.setDrawerType(COLLAPSED_DRAWER);
  };

  setMiniDrawer (){
    this.props.setDrawerType(MINI_DRAWER);
  };

  goToGame = (searchShortCode) => {
      this.props.history.push({ pathname: `/app/dashboard/search/code/${searchShortCode}` });
  };

  static getDerivedStateFromProps(nextProps,prevState){
      if (nextProps.betSlipData.bets){
      return {
        betSlipData:nextProps.betSlipData,
        type: nextProps.betSlipData.type ? nextProps.betSlipData.type : 0
      }
    } else {
      return null
    }
  }

  handleBetPlace(){
    let {betSlipData} = this.state;
    let { betPlaceLoader } = this.props;
     let type = betSlipData.type;
     if (!betPlaceLoader) {
        this.props.setSlip({...betSlipData,type});
     }
  }

  maxBet(reference_id){
    let {betSlipData, maxBetLoader} = this.state;

    let type = betSlipData.type;
    const name = reference_id ? reference_id : 'multy';
    setTimeout(() => {
      this.setState({ maxBetLoader: {...this.state.maxBetLoader, [name]: false} })
    }, 3000);
    this.setState(async ()=>{
      const maxBet = await this.props.getMaxBet({...betSlipData, reference_id, type }, false, reference_id);
      this.calculate({ target: {value: maxBet}});
      this.setState({  maxBetLoader: {...this.state.maxBetLoader, [name]: true }});
    });    
  }

  render() {
    let { betSlipData } = this.state;
    let { globalSettings, betPlaceLoader } = this.props;
    let type = betSlipData.type;
    const currency = globalSettings.betting ? globalSettings.betting.ExchangeRate : '';
    return betSlipData.bets && betSlipData.bets.length ? (
      <div className="side-nav-option">
        <div className={'bet-slip__empty-btn'} >
          <span onClick={() => {this.props.emptySlip()}}>
            <span><IntlMessages id='Empty betSlip'/></span>
            <i className="zmdi zmdi-close text-white"/>
          </span>
        </div>
          <div className='bet-slip'>
            {(betSlipData.bets && betSlipData.bets.length) && betSlipData.bets.map((bet,betIndex)=>(
                <div key={betIndex} className='bet-slip__row'>
                  <div key={betIndex} className='bet-slip__row-event-name'>
                      <div>
                          <span style={{color:'#62c462'}}>{(bet.isLive ? 'LIVE' : bet.start_time) + ' '}</span>
                          <span>{bet.event_name}</span>
                      </div>
                    <i onClick={()=>{this.props.removeSlip({reference_id:bet.reference_id,type,amount:betSlipData.amount}) }} className="zmdi zmdi-close text-white"/>
                  </div>
                  <div className='bet-slip__row-info'>
                      <div className='bet-slip__market-price'>
                          <div>
                              <span onClick={()=>this.goToGame(bet.short_code)} style={{color:'#62c462',cursor:"pointer"}}>{bet.short_code + ' '}</span>
                              <span>{bet.market_name}:{bet.price_name.split(' ')?.[0]} {isNaN(bet.price_hcap) ? `[${bet.price_hcap}]` : +bet.price_hcap ?  `[${+bet.price_hcap}]` : ''}</span>
                          </div>
                         <div>{bet.price_rate ? fixByRule(Number(bet.price_rate)) : ''}</div>
                      </div>
                      {
                          bet.errors && bet.errors.length > 0 && bet.errors.map((error,errorKey)=>(
                              <div className={'bet-slip__event-error'} key={errorKey}>
                                  <span>{error.message}</span>
                              </div>
                          ))
                      }
                      {type === 0 && 
                        <div className='bet-slip__maxbet'>
                          <input onChange={(event) =>this.calculate(event,betIndex)} value={bet.amount} onKeyPress={this.validate} type="text" maxLength="10" placeholder={'Bet Amount'} />
                          {globalSettings.betting.maxBetButtonCB ?
                            <button 
                              disabled={this.state.maxBetLoader[bet.reference_id]}
                              onClick={() => this.maxBet(bet.reference_id)} 
                              className='bet-slip__maxbet__btn'
                            >
                              {this.state.maxBetLoader[bet.reference_id] ? 
                                <CircularProgress className='bet-place-loader' /> : 'MAX'}
                            </button> : null
                          }
                        </div>
                      }
                  </div>
                </div>
            ))}
              <div ref={this.messagesEndRef} />
          </div>
          <div className={'bet-slip__place-bet'}>
              {
                  type === 1 ?
                      <div className={'bet-slip__place-bet-head'}>
                          Total Odds<span>{betSlipData.total_odds ? betSlipData.total_odds : 0}</span>
                      </div>
                      :
                      <div className={'bet-slip__place-bet-head'}>
                          Total Bet Amount<span>{betSlipData.bets.length}</span>
                      </div>
              }
            { type === 1 && 
              <div className='bet-slip__maxbet'>
                <input onChange={this.calculate} value={betSlipData.amount} onKeyPress={this.validate} type="number" maxLength='10' placeholder={'Bet Amount'} />
                {globalSettings.betting.maxBetButtonCB ? 
                  <button 
                    disabled={this.state.maxBetLoader['multy']} 
                    onClick={()=>this.maxBet()} 
                    className='bet-slip__maxbet__btn'
                  >
                    {this.state.maxBetLoader['multy'] ? 
                      <CircularProgress className='bet-place-loader' /> : 'MAX'
                    }
                  </button> : null
                }
              </div>
            }
            <div className={'bet-slip__place-bet-body'}>
                {betSlipData.errors && betSlipData.errors.length > 0 &&
                <div className={'bet-slip__place-bet-error-title'}>
                    More Errors <i className="zmdi zmdi-info" />
                    <div className={'bet-slip__place-bet-errors'}>
                        {betSlipData.errors.map((error,index)=>(
                            <div key={index}>
                                <span>{index+1}. {error.message} ({error.errorCount})</span>
                            </div>
                        ))}
                    </div>
                </div>}
                {betSlipData.beforeAmountTax ?
                    <div>Stake after Tax<span>{betSlipData.total_amount + ' ' + currency}</span></div>
                    : <></>
                }
                {type !== 1 ?
                    <div>Total Amount<span>{(betSlipData.beforeAmountTax || betSlipData.total_amount) + ' ' + currency}</span></div>
                    : <></>
                }

                {!!betSlipData.bonus && (globalSettings.label.project === 'VAMOSETH' || globalSettings.label.project === 'HABESHAETH' 
                ? <div>Multi bonus<span>{(betSlipData.bonus || 0) + ' ' + currency} </span></div>
                : <div>Possible Bonus<span>{(betSlipData.bonus || 0) + ' ' + currency} </span></div>)
                }

                {!!betSlipData.fixedPercentBonusAmount && globalSettings.label.project === 'VAMOSETH' 
                && <div>Bonus on Win 10%: <span>{(betSlipData.fixedPercentBonusAmount || 0) + ' ' + currency} </span></div>
                }

              <div>
                {betSlipData.possible_tax ? (globalSettings.label.project === 'PMBETZM' ? `Withholding Tax ${globalSettings && globalSettings.betting && globalSettings.betting.Tax ? globalSettings.betting.Tax + '%' : ''}` : 'Possible Tax') : ''}
                <span>{betSlipData.possible_tax ? (betSlipData.possible_tax + ' ' + currency) : ''}</span>
              </div>
              {betSlipData.possible_extra_bonus ? <div>Winning Bonus<span>{betSlipData.possible_extra_bonus + ' ' + currency}</span></div> : null}
              <div>Possible Winning<span>{betSlipData.winning_with_tax ? (betSlipData.winning_with_tax + ' ' + currency) : 0}</span></div>

              <button className={betPlaceLoader ? 'disabled-bet-place-btn' : ''} onClick={this.handleBetPlace}>
                {betPlaceLoader ? <CircularProgress className='bet-place-loader' /> : <IntlMessages id='Place bet'/>}
              </button>
            </div>
          </div>
      </div>

    ) : <div className="side-nav-option" style={!betSlipData.bets || !betSlipData.bets.length ? {height:'100%'} : {height:'calc(100% - 48px)'}}>
          <div className='bet-slip__isEmpty'>
            Bet Slip is Empty
          </div>
        </div>
    }
}

const mapStateToProps = ({settingsReducer,betSlipReducer,authReducer}) => {
  const {drawerType, navigationStyle, horizontalNavPosition,globalSettings} = settingsReducer;
  const { betSlipData, betLoader, betPlaceLoader } = betSlipReducer;
  const { authUser } = authReducer;
  return {drawerType, navigationStyle, horizontalNavPosition,betSlipData, betLoader, betPlaceLoader, globalSettings, authUser}
};

const mapDispatchToProps = {
    changeNavigationStyle,
    setDrawerType,
    setHorizontalMenuPosition,
    removeSlip,
    setBetSlip,
    setSlip,
    getMaxBet,
    emptySlip
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customizer));


const priceCodes = {
  PRICE_CODES_1X2: [ { a: false, n: '1' }, { a: false, n: 'X' }, { a: false, n: '2' } ],
  PRICE_CODES_DC: [ { a: false, n: '1X' }, { a: false, n: '12' }, { a: false, n: 'X2' } ],
  PRICE_CODES_12: [ { a: false, n: '1' }, { a: false, n: '2' } ],
  PRICE_CODES_OU: [ { a: false, n: 'Over' }, { a: false, n: 'Under' } ],
}

const { PRICE_CODES_1X2, PRICE_CODES_DC, PRICE_CODES_12, PRICE_CODES_OU } = priceCodes

export const priceGroups = {
  PRICE_GROUP_1X2_AH_OU: {
    match: { pr: PRICE_CODES_1X2 },
    handicap: { pr: PRICE_CODES_12 },
    quarter: { pr: PRICE_CODES_OU },
  },
  PRICE_GROUP_1X2_DC: {
    match: { pr: PRICE_CODES_1X2 },
    double: { pr: PRICE_CODES_DC },
  },
  PRICE_GROUP_12_OU_OU: {
    match: { pr: PRICE_CODES_12 },
    total: { pr: PRICE_CODES_OU },
    quarter: { pr: PRICE_CODES_OU }
  },
  PRICE_GROUP_12_12_OU: {
    match: { pr: PRICE_CODES_12 },
    handicap: { pr: PRICE_CODES_12 },
    total: { pr: PRICE_CODES_OU },
  },
  PRICE_GROUP_12: {
    match: { pr: PRICE_CODES_12 },    
  }
}
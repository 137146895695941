import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";
import _ from 'lodash';
import {getBalanceLimit} from "./BalanceLimit";

import {
    GET_PAYOUT_TICKET_DATA,
    MAKE_PAYOUT_TICKET,
    CLEAR_PAYOUT_TICKET_DATA,
    DISABLE_BUTTON_PAYOUT_TICKET
} from '../constants/ActionTypes';

export function clearDataPayoutTicket() {
    return dispatch => {
        dispatch({type:CLEAR_PAYOUT_TICKET_DATA});
    }
}

export function payoutTicketData(data) {
    return dispatch => {
        dispatch({type:CLEAR_PAYOUT_TICKET_DATA});
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        if (!user_token) {
            dispatch({type:GET_PAYOUT_TICKET_DATA,data:[]})
        } else {
            axios({
                url: `${API_URL}/other/ticketPayout`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data
            }).then(result => {
                if (_.isEmpty(result.data.data)){
                    dispatch({type:GET_PAYOUT_TICKET_DATA,data:result.data.data,result:['Ticket Not Found']})
                }else {
                    dispatch({type:GET_PAYOUT_TICKET_DATA,data:result.data.data})
                }
            }).catch(e => {
                dispatch({type:GET_PAYOUT_TICKET_DATA,data:[]})
            })
        }
    }
}

export function makePayouts(data) {
    return dispatch => {
        dispatch({type:DISABLE_BUTTON_PAYOUT_TICKET});
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        if (!user_token) {
            dispatch({type:MAKE_PAYOUT_TICKET,data:[]})
        } else {
            axios({
                url: `${API_URL}/other/makePayout`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data
            }).then(result => {
                dispatch({type:DISABLE_BUTTON_PAYOUT_TICKET});
                dispatch(payoutTicketData(data));
                dispatch(getBalanceLimit());
            }).catch(e => {
                console.log(e);
                dispatch({type:DISABLE_BUTTON_PAYOUT_TICKET});
                dispatch({type:MAKE_PAYOUT_TICKET,data:e.response.data.message})
            })
        }
    }
}


import {
    SET_GAME_DOGS_DATA,
    CHANGE_DOGS_START,
    SET_DOGS_BET_PLACE,
    SET_LOADER_DOGS
} from '../../constants/ActionTypes'

const INIT_STATE = {
    gameData: {},
    lastGameData: [],
    setBetPlace: false,
    gameBetLoader:false,
    readyStart: 'pending'
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_GAME_DOGS_DATA: {
            return {...state, gameData: action.gameData,lastGameData: action.lastGameData,readyStart:'start'}
        }
        case SET_LOADER_DOGS: {
            return {...state, gameBetLoader: action.payload}
        }
        case CHANGE_DOGS_START: {
            return {...state, readyStart: 'pending',gameData: {},lastGameData: []}
        }
        case SET_DOGS_BET_PLACE: {
            return {...state, setBetPlace: action.payload}
        }
        default:
            return state;
    }
}

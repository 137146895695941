const _ = require("lodash");
const {calculate} = require("bet-calculation");

const bonusTypeMap = {
    '19': 18,
    '21': 20, //old TZ
    '21': 23, //old TZ
    '24': 23, //old TZ
    '25': 26
};
class BetCalculations {

    static sport(globParams,slip) {
        if([0,1].includes(Number(slip.type))){
            //rewrite account configs
            const bonusType = slip.isFastBet ?
                globParams.configs.project === 'PMBETTZ' ?
                    globParams.authUser['override_bonusType'] || slip.bonusType : 
                    globParams.country['BonusType'] : 
                globParams.authUser['override_bonusType'] || globParams.country['BonusType'];
            const maxWin = globParams.authUser['override_maxWin'] || globParams.country['MaxWinSum'];
            return calculate({label: {
                multyBonusRuler: globParams.configs['multyBonusRulerCB'],
                maxOddGlobal: globParams.configs.maxOddGlobal,
                betAmountVatTax: globParams.configs['betAmountVatTax'],
                bonusType: bonusType,
                tax: globParams.country['Tax'],
                compensationDeduction: globParams.country['CompensationDeduction'],
                amountBetSport: { maxWin },
                fixedPercentBonus: globParams.configs.fixedPercentBonus || 0,
            }}, slip);
        }
        let bonusType = Number(globParams.country['BonusType']);
        let taxPercent = Number(globParams.country['Tax']);
        let maxWin = Number(globParams.country['MaxWinSum']);
        let multyBonus = globParams.configs['multyBonusRulerCB'];
        //let compensationDeduction = Number(globParams.country['CompensationDeduction']);
        const maxOddGlobal = Number(globParams.configs.maxOddGlobal) || 100000;
        let computerBonus = 0;

        slip = Object.assign({}, slip, {possible_winning: 0, total_odds: 1});

        let betsPriceRates = BetCalculations.getBetsPriceRates(slip["bets"]);
        let betsCount = betsPriceRates.length;
        let countBonus = 0;
        if (betsCount > multyBonus.length - 1){
            countBonus = multyBonus[multyBonus.length - 1];
        }else {
            countBonus = multyBonus[betsCount];
        }

        switch (slip.type) {
            case 0: {
                slip.total_amount = 0;
                _.each(slip.bets, (bet) => {
                    if(bet) {
                        slip.total_odds *= Number(bet['price_rate']);
                        slip.total_amount += Number(bet['amount']);
                        slip.possible_winning += Number(bet['price_rate']) * Number(bet['amount']);
                    }
                });

                if(bonusType === 13 || bonusType === 14){
                    const betAmountVatTax = globParams.configs['betAmountVatTax'];
                    slip.beforeAmountTax = slip.total_amount;
                    slip.total_amount = slip.total_amount / betAmountVatTax;
                    slip.possible_winning = slip.possible_winning / betAmountVatTax;
                }

                if(bonusType === 14){
                    slip.bonus = (slip.beforeAmountTax || slip.total_amount) >= 100 ? (Number(slip.possible_winning) * 0.1) : 0;
                }else {
                    slip.bonus = 0;
                }

                if (slip.total_odds > maxOddGlobal) {
                    slip.total_odds = maxOddGlobal;
                }
                break;
            }
            case 1: {
                slip.total_amount = slip.amount;
                _.each(slip.bets, (value) => {
                    if(value) {
                        slip.total_odds *= Number(value.price_rate);
                    }
                });

                if (slip.total_odds > maxOddGlobal) {
                    slip.total_odds = maxOddGlobal;
                }

                slip.possible_winning = Number(slip.total_odds) * Number(slip.total_amount);

                if(bonusType === 13 || bonusType === 14){
                    const betAmountVatTax = Number(globParams.configs['betAmountVatTax']);
                    slip.beforeAmountTax = slip.total_amount;
                    slip.total_amount = slip.total_amount / betAmountVatTax;
                    slip.possible_winning = slip.possible_winning / betAmountVatTax;
                }

                slip.bonus = Number(countBonus) * Number(slip.possible_winning) / 100;

                if(bonusType === 14){
                    slip.bonus = (slip.beforeAmountTax || slip.total_amount) >= 100 ? (Number(slip.possible_winning) * 0.1) : 0;
                }
                // let compensation = ((100 * slip.possible_winning - Number(taxPercent) * slip.total_amount) / (100 - Number(taxPercent))) - slip.possible_winning;
                //
                // const hasLive = this.hasLive(slip.bets);
                //
                // if ([6, 7, 8].includes(Number(bonusType))) {
                //     if (betsCount < 15) {
                //         slip.bonus = (!hasLive) ? (compensation * compensationDeduction) : 0;
                //     } else if (betsCount >= 15 ) {
                //         slip.bonus = (!hasLive) ? slip.bonus : 0;
                //     }
                // }
                break;
            }
            case 'keno':
                if (slip.bets[0]) {
                    // let amount = 0;
                    const maxWinningBonus = [0, 3, 10, 45, 80, 150, 500, 1000, 2000, 5000, 10000];
                    _.each(slip.bets, (betLine) => {
                        slip.total_amount +=  Number(betLine.line_sum);
                        slip.possible_winning += Number(betLine.line_sum) * maxWinningBonus[betLine.numLength];
                    });
                }
                break;
            case 'kaboom':
                if (slip.bets[0]) {
                    const maxWinningBonus = 15000;
                    _.each(slip.bets, (betLine) => {
                        slip.total_amount +=  Number(betLine.line_sum);
                        slip.possible_winning += Number(betLine.line_sum) * maxWinningBonus;
                    });
                }
                break;
            case 'dogs':
            case 'horses':
                if (slip.bets[0]) {
                    _.each(slip.bets, (betLine) => {
                        slip.total_amount +=  Number(betLine.line_sum);
                        slip.possible_winning += betLine.line_value * Number(betLine.line_sum);
                    });
                }
                break;
            case 'wof':
                if (slip.bets[0]) {
                    _.each(slip.bets, (betLine) => {
                        slip.total_amount +=  Number(betLine.line_sum);
                        slip.possible_winning += (36 / betLine.numLength) * Number(betLine.line_sum);
                    });
                }
                break;
                default: break;
        }

        slip.comp_bonus = computerBonus;
        if (slip.possible_winning) {
            slip.possible_winning = slip.bonus ? slip.possible_winning + slip.bonus : slip.possible_winning;
            if(bonusType === 14){
                if(slip.possible_winning > 1000){
                    slip.possible_tax = slip.possible_winning * taxPercent / 100;
                }else {
                    slip.possible_tax = 0;
                }
            }else {
                slip.possible_tax = (Number(slip.possible_winning) - Number((slip.beforeAmountTax || slip["total_amount"]))) * taxPercent / 100;
                slip.possible_tax = slip.possible_tax < 0 ?  Number(slip.possible_winning) * taxPercent / 100 : slip.possible_tax;
            }

            if (globParams.configs.project === 'BWANABET' && slip.type !== 'sport') {
                slip.winning_with_tax = slip.possible_winning
            } else {
                slip.winning_with_tax = slip.possible_winning - slip.possible_tax;
            }

            if(slip.winning_with_tax > maxWin) {
                const addition = (maxWin * taxPercent * 0.01) / (1 - taxPercent*0.01);
                if(slip.bonus) {
                    slip.bonus = addition;
                    slip.possible_winning = maxWin + slip.bonus;
                } else {
                    slip.possible_winning = Math.min(addition + maxWin, slip.possible_winning);
                }
                if(bonusType === 14){
                    slip.possible_tax = slip.possible_winning * taxPercent / 100;
                } else {
                    slip.possible_tax = (Number(slip.possible_winning) - Number((slip.beforeAmountTax || slip["total_amount"]))) * taxPercent / 100;
                    slip.possible_tax = slip.possible_tax < 0 ?  Number(slip.possible_winning) * taxPercent / 100 : slip.possible_tax;
                }
                slip.winning_with_tax = slip.possible_winning - slip.possible_tax;
                if (globParams.configs.project === 'BWANABET' && slip.type !== 'sport') {
                    slip.winning_with_tax = slip.possible_winning
                } else {
                    slip.winning_with_tax = slip.possible_winning - slip.possible_tax;
                }
            }
        }

        // to fixed all number fields...
        for (let i in slip) {
            if (typeof slip[i] === 'number') {
                slip[i] = Number(slip[i].toFixed(2))
            }
        }
        return slip
    }

    /*
      Short function to detect if there are bets on live events;
    */
    static hasLive(bets) {
        return (typeof _.find(bets, function (item) {
            return item ? (item["event_src"] === 2 || item["price_version"] === 3 || item["isLive"] || item["is_live"]) : undefined;
        }) !== 'undefined')
    }

    static getBetsPriceRates(bets) {
        let betsPriceRates = [];

        _.each(bets, (bet) => {
            if(bet && bet.price_rate)
                betsPriceRates.push(bet["price_rate"]);
        });

        return betsPriceRates;
    }

    static genPackId() {
        let t = Math.ceil(new Date().getTime() / (1000)).toString();
        let d = Math.ceil(new Date().getTime() / (1000 * 86400) - 17000).toString();
        let t5 = d + t.substring(t.length - 5, t.length);
        let pad = "000000000";
        return pad.substring(0, pad.length - t5.length) + t5;
    }

}

export default BetCalculations;

import { LIVE_SPORT_IDS } from "../constants/ids"
import { priceGroups } from "./pricesData"

const { 
  FOOTBALL, CYBER_FOOTBALL, BASKETBALL, CYBER_BASKETBALL, TENNIS, E_SPORTS,
  VOLLEYBALL, TABLE_TENNIS, CYBER_TENNIS, CYBER_HOCKEY, BASKETBALL_3x3, HANDBALL, ICE_HOCKEY, FUTSAL, 
  SNOOKER, CRICKET, DARTS, AMERICAN_FOOTBALL
} = LIVE_SPORT_IDS

const { PRICE_GROUP_1X2_AH_OU, PRICE_GROUP_12_12_OU, PRICE_GROUP_12_OU_OU, PRICE_GROUP_12 } = priceGroups

export const sportVerisons = {
  SPORT_VERSION_1: {
    ids: [FOOTBALL, CYBER_FOOTBALL, FUTSAL, HANDBALL],
    codes: ['1x2', 'AH', 'OU'],
    prices: PRICE_GROUP_1X2_AH_OU
  },
  SPORT_VERSION_2: {
    ids: [CYBER_BASKETBALL],
    codes: ['HA_OT', 'OU_Q1', 'OU_Q2', 'OU_Q3', 'OU_Q4', 'OU_OT'],
    prices: PRICE_GROUP_12_OU_OU
  },
  SPORT_VERSION_3: {
    ids: [TENNIS, VOLLEYBALL, TABLE_TENNIS, CYBER_TENNIS, CYBER_HOCKEY, SNOOKER],
    codes: ['HA', 'OU', 'AH', 'AH_G', 'OU_G'],
    prices: PRICE_GROUP_12_12_OU
  },
  SPORT_VERSION_4: {
    ids: [BASKETBALL_3x3, BASKETBALL, ICE_HOCKEY, AMERICAN_FOOTBALL],
    codes: ['HA_OT', 'AH_OT', 'OU_OT'],
    prices: PRICE_GROUP_12_12_OU
  },
  SPORT_VERSION_5: {
    ids: [E_SPORTS],
    codes: ['HA', 'AH_G', '1x2', '1x2_M1', '1x2_M2', '1x2_M3', '1x2_M4', '1x2_M5', 'OU_G', 'OU_M1', 'OU_M2', 'OU_M3', 'OU_M4', 'OU_M5'],
    prices: PRICE_GROUP_12_12_OU
  },
  SPORT_VERSION_6: {
    ids: [DARTS, CRICKET],
    codes: ['HA'],
    prices: PRICE_GROUP_12
  }
}


const { 
  SPORT_VERSION_1, SPORT_VERSION_2, SPORT_VERSION_3,
  SPORT_VERSION_4, SPORT_VERSION_5, SPORT_VERSION_6 } = sportVerisons;

export const liveSportsData = {
  [FOOTBALL]: {
    id: FOOTBALL,
    text: 'Football',
    codes: SPORT_VERSION_1.codes,
    urlName: 'football',
    periodText: {},
    priority: 1,
  },
  [CYBER_FOOTBALL]: {
    id: CYBER_FOOTBALL,
    text: 'Cyber Football',
    codes: SPORT_VERSION_1.codes,
    urlName: 'cyberfootball',
    periodText: {},
    priority: 2,
  },
  [BASKETBALL]: {
    id: BASKETBALL,
    text: 'Basketball',
    codes: SPORT_VERSION_4.codes,
    urlName: 'basketball',
    periodText: {
      'Quarter1': 'Quarter 1',
      'Quarter2': 'Quarter 2',
      'Quarter3': 'Quarter 3',
      'Quarter4': 'Quarter 4'
    },
    priority: 3,
  },
  [CYBER_BASKETBALL]: {
    id: CYBER_BASKETBALL,
    text: 'Cyber Basketball',
    codes: SPORT_VERSION_2.codes,
    urlName: 'cyberbasketball',
    periodText: {
      'Quarter1': 'Quarter 1',
      'Quarter2': 'Quarter 2',
      'Quarter3': 'Quarter 3',
      'Quarter4': 'Quarter 4'
    },
    priority: 4,
  },
  [BASKETBALL_3x3]: {
    id: BASKETBALL_3x3,
    text: 'Basketball 3x3',
    codes: SPORT_VERSION_4.codes,
    urlName: 'basketball3x3',
    periodText: {
      'Quarter1': 'game',
      'Not Started': 'game',
    },
    priority: 5,
  },
  [VOLLEYBALL]: {
    id: VOLLEYBALL,
    text: 'Volleyball',
    codes: SPORT_VERSION_3.codes,
    urlName: 'volleyball',
    periodText: {},
    priority: 6,
  },
  [TENNIS]: {
    id: TENNIS,
    text: 'Tennis',
    codes: SPORT_VERSION_3.codes,
    urlName: 'tennis',
    periodText: {},
    priority: 7,
  },
  [TABLE_TENNIS]: {
    id: TABLE_TENNIS,
    text: 'Table tennis',
    codes: SPORT_VERSION_3.codes,
    urlName: 'tableTennis',
    periodText: {},
    priority: 8,
  },
  [CYBER_TENNIS]: {
    id: CYBER_TENNIS,
    text: 'Cyber Tennis',
    codes: SPORT_VERSION_3.codes,
    urlName: 'cybertennis',
    periodText: {},
    priority: 9,
  },
  [CYBER_HOCKEY]: {
    id: CYBER_HOCKEY,
    text: 'Cyber Hockey',
    codes: SPORT_VERSION_3.codes,
    urlName: 'cyberhockey',
    periodText: {},
    priority: 10,
  },
  [E_SPORTS]: {
    id: E_SPORTS,
    text: 'E-Sports',
    codes: SPORT_VERSION_5.codes,
    urlName: 'esports',
    periodText: {},
    priority: 11,
  },
  [HANDBALL]: {
    id: HANDBALL,
    text: 'Handball',
    codes: SPORT_VERSION_1.codes,
    urlName: 'handball',
    periodText: {},
    priority: 12,
  },
  [ICE_HOCKEY]: {
    id: ICE_HOCKEY,
    text: 'Ice_Hockey',
    codes: SPORT_VERSION_4.codes,
    urlName: 'ice_hockey',
    periodText: {},
    priority: 13,
  },
  [FUTSAL]: {
    id: FUTSAL,
    text: 'Futsal',
    codes: SPORT_VERSION_1.codes,
    urlName: 'futsal',
    periodText: {},
    priority: 14,
  },
  [SNOOKER]: {
    id: SNOOKER,
    text: 'Snooker',
    codes: SPORT_VERSION_3.codes,
    urlName: 'snooker',
    periodText: {},
    priority: 15,
  },
  [CRICKET]: {
    id: CRICKET,
    text: 'Cricket',
    codes: SPORT_VERSION_6.codes,
    urlName: 'cricket',
    periodText: {},
    priority: 16,
  },
  [DARTS]: {
    id: DARTS,
    text: 'Darts',
    codes: SPORT_VERSION_6.codes,
    urlName: 'darts',
    periodText: {},
    priority: 17,
  },
  [AMERICAN_FOOTBALL]: {
    id: AMERICAN_FOOTBALL,
    text: 'American Football',
    codes: SPORT_VERSION_4.codes,
    urlName: 'american_football',
    periodText: {
      'Quarter1': 'Quarter 1',
      'Quarter2': 'Quarter 2',
      'Quarter3': 'Quarter 3',
      'Quarter4': 'Quarter 4'
    },
    priority: 18,
  },
}

import axios from 'axios';
import {labelHandler} from '../util/configs'

import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  SET_GLOBAL_SETTINGS,
  SET_GLOBAL_LOADER
} from '../constants/ActionTypes';

import {API_URL} from "../util/configs";

export function getGlobalSettings() {
  return dispatch => {
    dispatch(setGlobalLoader());

    axios({
      url: `${API_URL}/other/getSettings`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    }).then(response => {
        if (!response.data.error){
        let countrySettings = labelHandler(response.data.data.label.project);
        dispatch(setGlobalSettings(response.data.data,countrySettings));
        dispatch(setGlobalLoader());
      } else {
        dispatch(setGlobalSettings([]));
        dispatch(setGlobalLoader());
      }
    }).catch(e =>{
      dispatch(setGlobalSettings([]));
      dispatch(setGlobalLoader());
    })
  }
}

export function setGlobalLoader() {
  return {
    type: SET_GLOBAL_LOADER,
  }
}

export function setGlobalSettings(globalSettings, countrySettings) {
  return {
    type: SET_GLOBAL_SETTINGS,
    payload:{
      globalSettings,
      countrySettings
    }
  };
}

export function toggleCollapsedNav(isNavCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
  return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
  return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
  return {type: DARK_THEME};
}

export function changeDirection() {
  return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

import {
    GET_DOWNLOADS
} from '../../constants/ActionTypes';

const INIT_STATE = {
    data:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DOWNLOADS: {
            return {...state, data:action.payload };
        }
        default:
            return state;
    }
}

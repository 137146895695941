import axios from 'axios'
import {API_URL} from '../util/configs';
import cookie from "react-cookies";

import {
    SET_SEARCH_DATA,
    SET_SEARCH_LOADER,

} from '../constants/ActionTypes';

const marketShortCodeMap = {
	"1":["1x2"], //	Match RESULT
	"2":["DC"], //	double chance
	"3":["OU"], //	over under
	"4":["IOU_T1"], //	home over under
	"5":["IOU_T2"], //	away  over under
	"6":["1x2_H1"], //	match result half time
	"7":["DC_H1"], //	double chance first half
	"8":["OU_H1"], //	over under first half
	"9":["IOU_T1_H1"], //	home over under first half
	"10":["IOU_T2_H1"], //	away over under first half
	"11":["TSG_T1"], //	home will  score
	"12":["TSG_T2"], //	away  will score
	"13":["BTS"], //	both team will  score
	"14":["TSG_T1_H1"], //	home team will  score first half
	"15":["TSG_T2_H1"], //	away team will  score first half
	"16":["BTS_H1"], //	both team will  score first half
	"17":["CS"], //	Correct Score
	"18":["CS_H1"], //	first half correct score
	"19":["CCS_101120","CCS_102021","CCS_102030","CCS_203031","CCS_000110","CCS_001020","CCS_000102","CCS_010211","CCS_010212","CCS_010203","CCS_020313"], //	Combinated Scores( 1:0 , 2:0 , 1:1 ...),
	"20":["HTFT"], //	HALF TIME / FULL TIME
	"21":["HTFT_OU250"], //	HALF TIME / FULL TIME & OVER/UNDER 1.5 , 2.5
	"22":["HTFT_BTS"], //	Half Time/Full Time/Both Teams To Score
	"23":["RTG"], //	RESULT/TOTAL GOALS
	"24":["RTGWO_T1","RTGWU_T1","RTGNLO_T1","RTGNLU_T1"], //	Home Win/Over , Home Win/Under , Home Not lose/Over, Home Not lose/Under
	"25":["RTGWO_T2","RTGWU_T2","RTGNLO_T2","RTGNLU_T2"], //	Away Win/Over , Away Win/Under , Away Not lose/Over, Away Not lose/Under
	"26":["DC_BTS"], //	Double Chance/Both Teams To Score
	"27":["1x2_10M","1x2_35M","1x2_40M","1x2_60M","1x2_75M"], //	1x2 (10 , 60, 75) minutes
	"28":["DC_10M","DC_30M","DC_50M","DC_70M","DC_60M","DC_75M","DC_40M","DC_35M"], //	Double Chance - (10, 30, 50, 60, 70, 75)  minutes
	"29":["1x2_0_15","1x2_16_30", "1x2_31_45", "1x2_46_60", "1x2_60_75", "1x2_75_90"], //	1x2 between 00:00 m and 15:00 m - 75:01 m and 90:00 m
	"30":["DC_0_15","DC_16_30", "DC_31_45", "DC_46_60", "DC_60_75", "DC_75_90"], //	Double Chance between 00:00 m and 15:00 m - 75:01 m and 90:00 m
	"31":["WMS1_T1", "WMS2_T1", "WMS3_T1", "WMOR1_T1", "WMOR2_T1", "WMGE1_T1", "WMGE2_T1", "WMSD1_T1", "WMSD2_T1"], //	Home Winning Margins ALL
	"32":["WMS1_T2", "WMS2_T2", "WMS3_T2", "WMOR1_T2", "WMOR2_T2", "WMGE1_T2", "WMGE2_T2", "WMSD1_T2", "WMSD2_T2"], //	Away Winning Margins ALL
	"33":["TNG01", "TNG12", "TNG23", "TNG34", "TNG45", "TG01_H1", "TG12_H1", "TG23_H1", "TG01_H2", "TG12_H2", "TG23_H2", "TG34_H2", "TG34_H1", "TG45_H2", "TG45_H1", "TNG26", "TNG13", "TNG24", "TNG25", "TNG35", "TNG46"], //	0 or 1 Goals - 4 or 5 Goals  , 0 or 1 Goals - 4 or 5 Goals(1st Half)
	"34":["TNG01_T1", "TNG12_T1", "TNG23_T1", "TNG34_T1", "TNG45_T1", "TNG01_T1_H1", "TNG12_T1_H1", "TNG23_T1_H1", "TNG01_T1_H2", "TNG12_T1_H2", "TNG23_T1_H2","TNG34_T1_H1", "TNG34_T1_H2", "TNG45_T1_H2"], //	Home 0 or 1 Goals - Home 3 or 4 Goals , Home 0 or 1 Goals - Home 3 or 4 Goals( 1st Half)
	"35":["TNG01_T2", "TNG12_T2", "TNG23_T2", "TNG12_T2_H1", "TNG12_T2_H2", "TNG34_T2", "TNG45_T2", "TNG01_T2_H1", "TNG23_T2_H1", "TNG01_T2_H2", "TNG23_T2_H2", "TNG34_T2_H2"], //	Away 0 or 1 Goals - Away 3 or 4 Goals  , Away 0 or 1 Goals - Home 3 or 4 Goals( 1st Half)
	"36":["TNG","TNG_H1","TNG_H2"], //	Total Goals Number, Total Goals Number(1st Half)
	"37":["TNG_T1","TNG_T1_H1","TNG_T1_H2"], //	Home Total Goals Number, Home Total Goals Number(1st Half)
	"38":["TNG_T2","TNG_T2_H1","TNG_T2_H2"], //	Away Goals Number, Away Goals Number(1st Half)
	"39":["S1G","S2G","S3G","S4G","S5G"], //	Which team will score the 1st , 2nd , 3rd , 4th, 5th goal?,
	"40":["SFM_W_T1","SFM_NW_T1","SFM_W_T2","SFM_NW_T2"], //	Home Score First/Win Match, Home Score First/Not Win Match , Away Score First/Win Match, Away Score First/Not Win Match
	"41":["HSH","HSH_T1","HSH_T2"], //	Highest Scoring Half, Home Highest Scoring Half , Away Highest Scoring Half
	"42":["AH","AH_H1","AH_H2"], //	Asian Handicap, Asian Handicap(1st Half) , Asian Handicap (2nd Half)
	"43":["OE","OE_H1","OE_H2"], //	ODD / EVEN , ODD/EVEN(1st half) ,  ODD/EVEN(2nd half) ,
	"44":["DNB","DNB_H1","DNB_H2"], //	Draw No Bet, Draw No Bet (1st Half) , Draw No Bet (2nd Half)
	"45":["TGBTS"], //	Results/Both Teams To Score  ,   Total Goals/Both Teams To Score
	"46":["HTOFT"], // 1st Half or Match   -
	"47":["WBH_T1"], // Home Win Both Halves -
	"48":["WBH_T2"], // Away Win Both Halves -
	"49":["WTN_T1"], // Home Win To Nill -
	"50":["WTN_T2"], // Away Win To Nill -
	"51":["TSG_M"],  // Goal In Interval
	"52":["RTG","TGBTS","RTGNLO_T1","RTGNLU_T1","RTGNLO_T2","RTGNLU_T2","DC_BTS","HTFT","HTFT_OU250"], // double bets
	"53":["OU_H2"], //	over under second half
	"54":["BTS_H2"], //	both team will  score second half
	"55":["TNGALL"], // number of goals
	"56":["TNGAL_T1"], // home number of goals
	"57":["TNGAL_T2"], // away number of goals
	"58":["HTFTDC"], // Half Time/Full Time Or Double Chance
	"59":["BTSO50"], // Each Team Total Goals Over
	"60":["BTSU50"], // Each Team Total Goals Under
	"61":["CFB_W_T1"], // Home Come From Behind and Win
	"62":["CFB_W_T2"], // Away Come From Behind and Win
	"63":["SBH"], // Both Teams To Score in Both Halves
	"64":["TSGBH"], // To Score in Both Halves
	"65":["H1OU_FTBTS"], // Both Teams To Score/1st Half Over(1.5)
	"66":["OG"], // Own Goal
	"67":["PA"], // Penalty Awarded
	"68":["RCARD"], // Red Cards
	"69":["Y_OU"], // Yellow OU
	"70":["Y_OU_H1"], // Yellow OU 1H
	"71":["COR_OU"], // Total Corners
	"72":["COR_OU_H1"], // Total Corners 1H
	"73":["COR_1x2"], // Corners 1x2
	"74":["COR_1ST"],
	"75":["Y_1ST"],
	"76":["PA_OR_RCARD"],
	"77":["PA_AND_RCARD"],
	"78":["SUB_OU"],
	"79":["SUB_OU_H1"],
    "80": ["RBTS"],
    "81": ["ROU"],
    "82": ["DC_OU"],
    "83": ["OUBTS"],
    "84": ["SBH_T1"],
    "85": ["SBH_T2"],
};

export const getSearchDataByShortCode = (val,type) => {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setSearchData())
        }else {
            dispatch({type:SET_SEARCH_LOADER});

            let marketId = null;
            if(type === 'shortCode'){
                const value = ''+val;
                const splited = value.split(".");
                if(splited[1]){
                    val = splited[0];
                    marketId = splited[1];
                }
            }
            axios({
                url: `${API_URL}/event/searchEvent`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {type: type === 'eventName' ? 'string' : "short_code",val}
            }).then(response => {
                if (!response.data.error){
                    const market_codes = marketId ? marketShortCodeMap[marketId] || null : null;
                    console.log(response.data.data);
                    axios({
                        url: `${API_URL}/event/getMarkets`,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization':`${user_token}`
                        },
                        data: {
                            eventId:response.data.data.event_id,
                            query: `
                mutation {
                    eventList(eventListInput: {
                      sportId: ${Number(response.data.data.sport_id)}
                      topEvents: false
                      country: "${response.data.data.country}"
                      competitionName: "${response.data.data.competition_name}"
                      eventId:${Number(response.data.data.event_id)}
                      ${market_codes? 'markets:'+ JSON.stringify(market_codes) : ''}
                  }) {
                      sportId
                      sportName
                      priority
                      visible
                      competitions {
                        competitionId
                        country
                        competitionName
                        events {
                          eventId
                          eventName
                          shortCode
                          eventStartTime
                          top
                          pricesCount
                          champRefId
                          team1RefId
                          team2RefId
                          collections {
                            collectionId
                            collectionName
                            markets {
                                marketId
                                marketName
                                marketCode
                                prices {
                                  referenceId
                                  priceName
                                  handicapValue
                                  rate
                                  createdDate
                                }
                             }
                          }
                        }
                      }
                    }
                  }
            `
                        }
                    }).then(resp => {
                        if (!resp.data.error){
                            dispatch(setSearchData(resp.data.data));
                        } else {
                            dispatch(setSearchData())
                        }
                    }).catch(e =>{
                        console.log('getMarkets',e);
                        dispatch(setSearchData())
                    })
                } else {
                    dispatch(setSearchData())
                }
            }).catch(e =>{
                console.log('getSearchDataByShortCode',e);
                dispatch(setSearchData())
            })
        }
    }
};

export const getSearchDataByLive = (query) => {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setSearchData())
        }else {
            dispatch({type:SET_SEARCH_LOADER});

            axios({
                url: `${API_URL}/event/searchLive`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {shortCode:query}
            }).then(response => {
                if (!response.data.data.error){
                    dispatch(setSearchData(response.data.data))
                } else {
                    dispatch(setSearchData())
                }
            }).catch(e =>{
                console.log('getSearchDataByShortCode',e);
                dispatch(setSearchData())
            })
        }
    }
};

export const setSearchData = (data=[]) => {
    return {
        type:SET_SEARCH_DATA,
        payload:data
    }
};

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const DRAWER_TYPE = 'DRAWER_TYPE';
export const FIXED_DRAWER = 'FIXED_DRAWER';
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER';
export const MINI_DRAWER = 'MINI_DRAWER';
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const WINDOW_WIDTH = 'WINDOW_WIDTH-width';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE-language';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION-direction';

export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE-navigation-style';
export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';
export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';

export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const ABOVE_THE_HEADER = 'ABOVE_THE_HEADER';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const BELOW_THE_HEADER = 'BELOW_THE_HEADER';

//Contact Module const

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const FETCH_ALL_CONTACT = 'FETCH_ALL_CONTACT';
export const FETCH_ALL_CONTACT_SUCCESS = 'FETCH_ALL_CONTACT_SUCCESS';
export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const ON_CONTACT_SELECT = 'ON_CONTACT_SELECT';
export const ON_ADD_CONTACT = 'ON_ADD_CONTACT';
export const ON_CONTACT_CLOSE = 'ON_CONTACT_CLOSE';
export const ON_FILTER_OPTION_SELECT = 'ON_FILTER_OPTION_SELECT';
export const ON_SAVE_CONTACT = 'ON_SAVE_CONTACT';
export const ON_DELETE_CONTACT = 'ON_DELETE_CONTACT';
export const ON_DELETE_SELECTED_CONTACT = 'ON_DELETE_SELECTED_CONTACT';
export const FILTER_CONTACT = 'FILTER_CONTACT';
export const GET_ALL_CONTACT = 'GET_ALL_CONTACT';
export const GET_UNSELECTED_ALL_CONTACT = 'GET_UNSELECTED_ALL_CONTACT';
export const ON_ALL_CONTACT_SELECT = 'ON_ALL_CONTACT_SELECT';
export const UPDATE_SEARCH_USER = 'UPDATE_SEARCH_USER';
export const ON_TOGGLE_DRAWER = 'ON_TOGGLE_DRAWER';
export const HANDLE_REQUEST_CLOSE = 'HANDLE_REQUEST_CLOSE';
export const HIDE_CONTACT_LOADER = 'HIDE_CONTACT_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'SIGNIN_GITHUB_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'FETCH_ALL_CHAT_USER';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'FETCH_ALL_CHAT_USER_CONVERSATION';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'FETCH_ALL_CHAT_USER_SUCCESS';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS';
export const FILTER_USERS = 'FILTER_USERS';
export const ON_SELECT_USER = 'ON_SELECT_USER';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const USER_INFO_STATE = 'USER_INFO_STATE';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const UPDATE_MESSAGE_VALUE = 'UPDATE_MESSAGE_VALUE';
export const UPDATE_SEARCH_CHAT_USER = 'UPDATE_SEARCH_CHAT_USER';


// PM AUTH Const
export const PM_USER_SIGN_IN = 'PM_USER_SIGN_IN';
export const PM_USER_SIGN_IN_SUCCESS = 'PM_USER_SIGN_IN_SUCCESS';
export const PM_USER_SIGN_IN_FAIL = 'PM_USER_SIGN_IN_FAIL';
export const PM_USER_SIGN_OUT_SUCCESS = 'PM_USER_SIGN_OUT_SUCCESS';
export const PM_USER_INFO = 'PM_USER_INFO';
export const PM_AUTH_LOADER = 'PM_AUTH_LOADER';
export const PM_USER_EMPLOYEE = 'PM_USER_EMPLOYEE';
export const PM_USER_EMPLOYEE_SUCCESS = 'PM_USER_EMPLOYEE_SUCCESS';
export const CLEAR_EMPLOYEE_MESSAGE = 'CLEAR_EMPLOYEE_MESSAGE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

// PreMatches Const
export const SET_PREMATCHES = 'SET_PREMATCHES';
export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const SET_MARKET_LIST = 'SET_MARKET_LIST';
export const SET_MARKET_COLLECTIONS = "SET_MARKET_COLLECTIONS";
export const PM_SHOW_LOADER = 'PM_SHOW_LOADER';

// Socket Io Const
export const LIVE_SOCKET_ON = 'LIVE_SOCKET_ON';
export const LIVE_SOCKET_EMIT = 'LIVE_SOCKET_EMIT';
export const LIVE_SOCKET_OFF = 'LIVE_SOCKET_OFF';

// BetSlip Const
export const SET_BET_SLIP = 'SET_BET_SLIP';
export const REMOVE_BET_SLIP = 'REMOVE_BET_SLIP';
export const EMPTY_BET_SLIP = 'EMPTY_BET_SLIP';
export const FAILED_BET_SLIP = 'FAILED_BET_SLIP';
export const SET_TICKET = 'SET_TICKET';

//SET GLOBAL CONFIGS

export const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS';
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
// Games
export const SET_GAME_KENO_DATA = 'SET_GAME_KENO_DATA';
export const CHANGE_KENO_START = 'CHANGE_KENO_START';
export const SET_KENO_BET_PLACE = 'SET_KENO_BET_PLACE';
export const SET_LOADER_KENO = 'SET_LOADER_KENO';

export const SET_GAME_DOGS_DATA = 'SET_GAME_DOGS_DATA';
export const CHANGE_DOGS_START = 'CHANGE_DOGS_START';
export const SET_DOGS_BET_PLACE = 'SET_DOGS_BET_PLACE';
export const SET_LOADER_DOGS = 'SET_LOADER_DOGS';

export const SET_GAME_KABOOM_DATA = 'SET_GAME_KABOOM_DATA';
export const CHANGE_KABOOM_START = 'CHANGE_KABOOM_START';
export const SET_KABOOM_BET_PLACE = 'SET_KABOOM_BET_PLACE';
export const SET_LOADER_KABOOM = 'SET_LOADER_KABOOM';

export const SET_GAME_HORSES_DATA = 'SET_GAME_HORSES_DATA';
export const CHANGE_HORSES_START = 'CHANGE_HORSES_START';
export const SET_HORSES_BET_PLACE = 'SET_HORSES_BET_PLACE';
export const SET_LOADER_HORSES = 'SET_LOADER_HORSES';

export const SET_GAME_WOF_DATA = 'SET_GAME_WOF_DATA';
export const CHANGE_WOF_START = 'CHANGE_WOF_START';
export const SET_WOF_BET_PLACE = 'SET_WOF_BET_PLACE';
export const SET_LOADER_WOF = 'SET_LOADER_WOF';

export const GET_VF_DATA = 'GET_VF_DATA';

//Other
export const GET_DOWNLOADS = 'GET_DOWNLOADS';
export const GET_STATISTICS_DATA = 'GET_STATISTICS_DATA';
export const SET_BETS_DATA = 'SET_BETS_DATA';
export const SET_PAYOUTS_DATA = 'SET_PAYOUTS_DATA';

export const SET_KASSAS_DATA = 'SET_KASSAS_DATA';
export const SET_BET_INFO_DATA = 'SET_BET_INFO_DATA';

export const GET_PAYOUT_TICKET_DATA = 'GET_PAYOUT_TICKET_DATA';
export const CLEAR_PAYOUT_TICKET_DATA = 'CLEAR_PAYOUT_TICKET_DATA';
export const MAKE_PAYOUT_TICKET = 'MAKE_PAYOUT_TICKET';
export const DISABLE_BUTTON_PAYOUT_TICKET = 'DISABLE_BUTTON_PAYOUT_TICKET';

// Website
export const WEBSITE_USER_INFO = 'WEBSITE_USER_INFO';
export const WEBSITE_WITHDRAW = 'WEBSITE_WITHDRAW';
export const WEBSITE_TICKET_OFFLINE = 'WEBSITE_TICKET_OFFLINE';
export const SET_GAME_ZONES = 'SET_GAME_ZONES';
export const SET_GAME_ZONE_USER = 'SET_GAME_ZONE_USER';

// Search
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_SEARCH_LOADER = 'SET_SEARCH_LOADER';

// Balance Limit
export const SET_BALANCE_LIMIT = 'SET_BALANCE_LIMIT';
export const BET_SLIP_LOADER = 'BET_SLIP_LOADER';
export const BET_PLACE_LOADER = 'BET_PLACE_LOADER';
export const SET_BET_SLIP_SHOW = 'SET_BET_SLIP_SHOW';
export const SET_BET_SLIP_HIDE = 'SET_BET_SLIP_HIDE';


// Finance
export const GET_FINANCE_DATA = 'GET_FINANCE_DATA';
export const GET_FINANCE_LOGS = 'GET_FINANCE_LOGS';
export const GET_FINANCE_BY_DAY_DATA = 'GET_FINANCE_BY_DAY_DATA';
export const SET_FINANCE_MODAL = 'SET_FINANCE_MODAL';


import React from 'react';
import {connect} from 'react-redux'
import {pmUserSignOut} from '../../actions';
import IntlMessages from 'util/IntlMessages';

class UserInfoPopup extends React.Component {
    constructor(){
        super();

        this.state = {
            modal:false
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(){
        this.setState({
            modal: !this.state.modal
        })
    }

    render() {
      return (
      <div>
        <div className="user-profile">
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{this.props.authUser ? this.props.authUser.username : ''}</h4>
            <small>{this.props.authUser ? this.props.authUser.user_type : ''}</small>
          </div>
        </div>
        <span className="jr-link dropdown-item text-muted" onClick={() => this.props.pmUserSignOut()}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="SIGN OUT"/>
        </span>
      </div>
    );
  }
}

const mapStateToProps = ({authReducer}) => {
    const {authUser} = authReducer;
    return {authUser}
};

export default connect(mapStateToProps, {pmUserSignOut})(UserInfoPopup);



import {
    SET_GAME_KABOOM_DATA,
    CHANGE_KABOOM_START,
    SET_KABOOM_BET_PLACE,
    SET_LOADER_KABOOM
} from '../../constants/ActionTypes'

const INIT_STATE = {
    gameData: {},
    lastGameData: [],
    gameBetLoader:false,
    setBetPlace: false,
    readyStart: 'pending'
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_GAME_KABOOM_DATA: {
            return {...state, gameData: action.gameData,lastGameData: action.lastGameData,readyStart:'start'}
        }
        case SET_LOADER_KABOOM: {
            return {...state, gameBetLoader: action.payload}
        }
        case CHANGE_KABOOM_START: {
            return {...state, readyStart: 'pending',gameData: {},lastGameData: []}
        }
        case SET_KABOOM_BET_PLACE: {
            return {...state, setBetPlace: action.payload}
        }
        default:
            return state;
    }
}

import React, {Component} from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import URLSearchParams from 'url-search-params'
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from '../constants/ThemeColors';

import MainApp from '../app/index';
import SignIn from './SignIn';
import ChangePassword from './ChangePassword';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import cookie from "react-cookies";

import {setDarkTheme, setThemeColor, setInitUrl, pmUserInfo, getGlobalSettings, getBalanceLimit} from '../actions';

import CircularProgressBig from "../components/CircularProgress";

const RestrictedRoute = ({component: Component, authUser, ...rest}) => {
  let token = cookie.select()['pm-token'] || null;
  return <Route {...rest} render={ (props) => token !== null 
    ? !authUser.sp_reset 
      ? <Component {...props} /> 
      : <Redirect to={ { pathname: '/change_password', state: {from: props.location} } }/> 
    : <Redirect to={ { pathname: '/signin', state: {from: props.location} } }/> } />;
};

class App extends Component {
  constructor(props){
    super(props);

    this.getColorTheme = this.getColorTheme.bind(this)
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    if (this.props.initURL && this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme-name")) {
      this.props.setThemeColor(params.get('theme-name'));
    }
    if (params.has("dark-theme")) {
      this.props.setDarkTheme();
    }
  }

  componentDidMount() {
      this.props.pmUserInfo();
      this.props.getGlobalSettings();
      this.props.getBalanceLimit();

  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default : createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  disableWarnings() {
      const consoleError = console.error.bind(console);

      console.error = (message, ...args) => {
          if (
              typeof message === 'string' &&
              (message.startsWith('[React Intl] Missing message:') ||
              message.startsWith('[React Intl] Cannot format message:'))
          ) {
              return;
          }
          consoleError(message, ...args);
      };
  }

  render() {
    const {match, location, isDarkTheme, locale, authUser, initURL, isDirectionRTL, globalLoader} = this.props;
    let {themeColor} = this.props;
    let applyTheme = createMuiTheme(indigoTheme);

    this.disableWarnings();

    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
      applyTheme = createMuiTheme(darkTheme)
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/app/dashboard/sports'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    } else if (location.pathname !== '/signin' && authUser === null) {
      return ( <Redirect to={'/signin'}/> );
    } 
    else if (location.pathname === '/change_password' && !authUser?.sp_reset ) {
      return ( <Redirect to={initURL}/> );
    }
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return !globalLoader ? (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <RTL>
                        <div className="app-main">
                            <Switch>
                                <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp}/>
                                <Route path='/signin' component={SignIn}/>
                                <Route path='/change_password' component={ChangePassword}/>
                                <Route component={ asyncComponent(() => import('../app/routes/extraPages/routes/404')) }/>
                            </Switch>
                        </div>
                    </RTL>
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    ) : <CircularProgressBig/>;
  }
}

const mapStateToProps = ({settingsReducer, authReducer}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL, globalSettings, countrySettings, globalLoader} = settingsReducer;
  const {authUser, authLoader, initURL} = authReducer;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, authUser, authLoader, initURL, globalSettings, countrySettings, globalLoader}
};

export default connect(mapStateToProps, {setInitUrl, setThemeColor, setDarkTheme, pmUserInfo, getGlobalSettings, getBalanceLimit})(App);

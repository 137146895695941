import {
    GET_VF_DATA
} from '../../constants/ActionTypes'

const INIT_STATE = {
    gameData: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VF_DATA: {
            return {...state, gameData:action.payload}
        }
        default:
            return state;
    }
}

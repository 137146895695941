import React from 'react';
import ReactDOM from 'react-dom';
import cookie from "react-cookies";
import axios from 'axios';
import store from './store';

axios.interceptors.response.use(data => {
  return Promise.resolve(data);
}, error => {
  let { globalSettings } = store.getState().settingsReducer
  if(globalSettings.label && globalSettings.label.CBTwoFactorAuth && error.response.status === 401){
    cookie.remove('pm-token',{path:'/'});
    cookie.remove('pm-token');
    store.dispatch({ type: 'PM_USER_SIGN_OUT_SUCCESS'});
  }
  return Promise.reject(error)
})




const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}

render();

import {
    SET_BETS_DATA,
    SET_BET_INFO_DATA
} from '../../constants/ActionTypes';

const INIT_STATE = {
    betsData:[],
    printInfo:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_BETS_DATA: {
            return {...state, betsData:action.payload };
        }
        case SET_BET_INFO_DATA: {
            return {...state, printInfo:action.payload };
        }
        default:
            return state;
    }
}

import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import URLSearchParams from 'url-search-params'
import _ from 'lodash';

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Button from '@material-ui/core/Button';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import BetSlipOptions from './BetSlipOptions';
import IntlMessages from 'util/IntlMessages';

import {changeDirection, setDarkTheme, setThemeColor,typeChangeSlip, setTicketShower, setBetPrintData,setBetSlipShowFunction} from '../../actions/index';
import GlobalModal from "../../components/GlobalModal";


class BetSlip extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerStatus: false,
      type: 0,
      bets:[]
    };

    this.toggleCustomizer = this.toggleCustomizer.bind(this);
    this.closeCustomizer = this.closeCustomizer.bind(this);
    this.toggleModal = this.toggleModal.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.betSlipData.bets){
      return {
        bets: nextProps.betSlipData.bets,
        type: nextProps.betSlipData.type ? nextProps.betSlipData.type : 0,
      }
    }
    else {
      return null
    }
  }

  toggleModal(){
    this.props.setTicketShower();
    this.props.setBetPrintData();

  }

  toggleCustomizer () {
    this.props.setBetSlipShowFunction(!this.props.betSlipShower);
  };

  closeCustomizer (){
      this.props.setBetSlipShowFunction(!this.props.betSlipShower);
  };

  handleThemeColor = (colorCode) => {
    this.props.setThemeColor(colorCode);
    const body = document.body.classList;
    body.remove(this.props.themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };

  handleDarkTheme = () => {
    this.props.setDarkTheme();
    const body = document.body.classList;
    body.toggle(this.props.themeColor);
    body.toggle('dark-theme');
  };

  handleChange = (event, value) => {
    this.setState({
      type:value
    });
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme-name")) {
      document.body.classList.add(params.get('theme-name'));
    } else {
      document.body.classList.add(this.props.themeColor);
    }
  }

  render() {
    const { betSlipData ,betSlipShower,globalSettings} = this.props;
    const { type, drawerStatus } = this.state;
    let bets = betSlipData.bets;
    return (
      !['JEGINA'].includes(globalSettings.label.project) &&
        <div className="theme-option">
          <Button onClick={this.toggleCustomizer} style={drawerStatus ? { display:'none' } : { display:'flex' }}>
            <span style={{paddingRight:'5px'}}>{bets && bets.length === 0 ? '' : bets.length}</span>
            <i className="zmdi-hc-2x zmdi zmdi-receipt text-white"/>
          </Button>
          <Drawer className="app-sidebar-content right-sidebar"
                  variant={"persistent"}
                  anchor="right"
                  open={betSlipShower}
                  onClose={this.closeCustomizer}>
            <div className="color-theme">
              <div className="color-theme-header">
                <h3 className="color-theme-title">BET SLIP: {bets && bets.length ? bets.length : ''}</h3>
                <Button className="icon-btn" onClick={this.closeCustomizer}>
                  <i className="zmdi zmdi-close text-white"/>
                </Button>
              </div>
              <div className="color-theme-body">
                <div className="row text-left">
                  {bets && bets.length > 0 &&
                  <Tabs value={type} onChange={this.handleChange} variant={"standard"} scrollButtons="off">
                    <Tab onClick={()=> type === 0 ? this.props.typeChangeSlip({...betSlipData,kind:1}) : false} style={{display:'flex',flexDirection:'row'}} className="tab" label={<IntlMessages id='Multi'/>} value={1} disabled={!(bets && bets.length >= 2)}/>
                    <Tab onClick={()=> type === 1 ? this.props.typeChangeSlip({...betSlipData,kind:0}) : false}
                        style={{display:(globalSettings.label && globalSettings.label.country === 'ETHIOPIA') ? 'none' : 'flex',flexDirection:'row'}} className="tab" label={<IntlMessages id='Single'/>} value={0} />
                  </Tabs>
                  }
                </div>
                <BetSlipOptions />
              </div>
            </div>
          </Drawer>
            {!_.isEmpty(this.props.printInfo) && this.props.ticketShow &&
            <GlobalModal
                isBet={true}
                isLogo={true}
                isPrintNow={true}
                dataStatistics={this.props.printInfo}
                toggleModal={this.toggleModal}
                slipOpen={this.props.ticketShow}
                contentClassName={'ticket-modal-content'}
                countrySettings={this.props.countrySettings}
            >
              <span>Print Preview</span>
              <div>
                <button onClick={this.toggleModal}>
                  Close
                </button>
              </div>
            </GlobalModal>
            }
        </div>
    );
  }
}

const mapStateToProps = ({settingsReducer,betSlipReducer, betsReducer }) => {
  const {themeColor, darkTheme, isDirectionRTL, countrySettings,globalSettings} = settingsReducer;
  const {printInfo} = betsReducer;
  const {betSlipData,ticketShow, betSlipShower} = betSlipReducer;
  return {themeColor, darkTheme, isDirectionRTL, betSlipData, printInfo, countrySettings, ticketShow,globalSettings ,betSlipShower}
};

export default withRouter(connect(mapStateToProps, {setThemeColor, setDarkTheme, changeDirection,typeChangeSlip,setTicketShower, setBetPrintData,setBetSlipShowFunction})(BetSlip));


import {
    SET_PREMATCHES,
    SET_EVENT_LIST,
    SET_MARKET_LIST,
    PM_SHOW_LOADER
} from '../constants/ActionTypes';

const INIT_STATE = {
    preMatches:false,
    eventList:false,
    marketList:false,
    queries:false,
    loader: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PREMATCHES: {
            return {...state, preMatches:action.payload}
        }
        case SET_EVENT_LIST: {
            return {...state, eventList:action.payload.eventList,queries:action.payload.queries}
        }
        case SET_MARKET_LIST: {
            return {...state, marketList:action.payload.marketList,loader:false,queries:{...state.queries,eventId:action.payload.eventId}}
        }
        case PM_SHOW_LOADER: {
            return {...state,loader:action.payload}
        }
        default:
            return state;
    }
}
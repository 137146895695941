export const isNumber = (number) => {
  return !isNaN(number) && Number(number);
}

export const fixByRule = (price) => {
  if (!isNumber(price)) return price;
  price = Number(price);
  if (price < 2) {
    return Number(price.toFixed(3));
  }
  if (price < 10 && price >= 2) {
    return Number(price.toFixed(2));
  }
  if (price < 50 && price >= 10) {
    return Number(price.toFixed(1));
  }
  if (price >= 50) {
    return Number(price.toFixed());
  }
  return Number(price);
};
import appLocaleData from 'react-intl/locale-data/sw';
import swMessages from '../locales/sw.json';

const swLang = {
  messages: {
    ...swMessages
  },
  locale: 'sw',
  data: appLocaleData
};
export default swLang;

import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";
import {getBalanceLimit,getBetPrintInfo} from '../actions'

import {
    SET_BET_SLIP,
    BET_PLACE_LOADER,
    FAILED_BET_SLIP,
    REMOVE_BET_SLIP,
    EMPTY_BET_SLIP,
    BET_SLIP_LOADER,
    SET_TICKET, SET_BET_SLIP_SHOW, SET_BET_SLIP_HIDE
} from '../constants/ActionTypes';

export function getSlip(){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(failedBetSlip())
        }else {
            axios({
                url: `${API_URL}/bet/getSlip`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(response => {
                if (!response.data.error && response.data.data){
                    if(!response.data.data.bets.length){
                        dispatch(setBetSlipShow(false));
                    }
                    dispatch(setBetSlip(response.data.data))
                } else {
                    dispatch(failedBetSlip())
                }
            }).catch(e =>{
                console.log('addSlip',e);
                dispatch(failedBetSlip())
            })
        }
    }
}

export function addSlip(bets,type,amount){
    return dispatch => {
        dispatch(setBetSlipShow(true));
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(failedBetSlip())
        }else {
            axios({
                url: `${API_URL}/bet/addSlip`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {amount,type,bet:bets}
            }).then(response => {
                if (!response.data.error){
                    dispatch(setBetSlip(response.data.data))
                } else {
                    dispatch(failedBetSlip())
                }
            }).catch(e =>{
                console.log('addSlip',e);
                dispatch(failedBetSlip())
            })
        }
    }
}

export function typeChangeSlip(slipData){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(failedBetSlip())
        }else {
            axios({
                url: `${API_URL}/bet/typeChangeSlip`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: slipData
            }).then(response => {
                if (!response.data.error){
                    dispatch(setBetSlip(response.data.data))
                } else {
                    dispatch(failedBetSlip())
                }
            }).catch(e =>{
                console.log('addSlip',e);
                dispatch(failedBetSlip())
            })
        }
    }
}

export function removeSlip(options){
    return dispatch => {
        dispatch(setBetSlipShow(true));
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(failedBetSlip())
        }else {
            axios({
                url: `${API_URL}/bet/removeSlip`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: options
            }).then(response => {
                if (!response.data.error){
                    if(!response.data.data.bets.length){
                        dispatch({type:SET_BET_SLIP_HIDE});
                    }
                    dispatch(removeBetSlip(response.data.data))
                } else {
                    dispatch(failedBetSlip())
                }
            }).catch(e =>{
                console.log('addSlip',e);
                dispatch(failedBetSlip())
            })
        }
    }
}

export function emptySlip(){
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(failedBetSlip())
        }else {
            axios({
                url: `${API_URL}/bet/emptySlip`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(response => {
                if(!response.data.error){
                    dispatch(emptyBetSlip());
                    dispatch({type:SET_BET_SLIP_HIDE})
                } else {
                    dispatch(failedBetSlip())
                }
            }).catch(e =>{
                console.log('emptySlip',e);
                dispatch(failedBetSlip())
            })
        }
    }
}

export function setBetSlip(data={}) {
    return {
        type:SET_BET_SLIP,
        payload:data
    }
}

export function emptyBetSlip() {
    return {
        type:EMPTY_BET_SLIP
    }
}

export function removeBetSlip(data={}) {
    return {
        type:REMOVE_BET_SLIP,
        payload:data
    }
}
export function setBetSlipShow(bool) {
    return {
        type:SET_BET_SLIP_SHOW,
        bool
    }
}

export function setBetSlipShowFunction(bool){
    return dispatch => {
        dispatch(setBetSlipShow(bool));
    }
}

export function failedBetSlip(data=[]) {
    return {
        type:FAILED_BET_SLIP,
        payload:data
    }
}

export function setSlip(slip,isOffline=false){

    return dispatch => {

        return new Promise(resolve => {
            let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

            dispatch(setBetLoader(true));
            dispatch(setBetPlaceLoader(true));

            if(!user_token){
                dispatch(failedBetSlip());
                dispatch(setBetLoader(false));
                dispatch(setBetPlaceLoader(false));
                resolve();
            }else {
                axios({
                    url: `${API_URL}/bet/setSlip`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization':`${user_token}`
                    },
                    data: {slip}
                }).then( response => {
                    if (isOffline){
                        dispatch(setBetSlipShow(true));
                        dispatch(setBetSlip(response.data.data));
                        dispatch(setBetLoader(false));
                        dispatch(setBetPlaceLoader(false));
                        resolve();
                    } else {
                        if(!response.data.error) {
                            betPlace(user_token).then((betResponse)=>{
                                if(!betResponse.data.error) {
                                    dispatch(getBetPrintInfo(
                                        {
                                            game_type: "sport",
                                            packageId: betResponse.data.data.packageId,
                                            searchTerm: ""
                                        }));
                                    dispatch(emptyBetSlip());
                                    dispatch(getBalanceLimit());
                                    dispatch(setTicketShower(true));
                                    dispatch(getSlip());
                                }else {
                                    dispatch(setBetSlip(betResponse.data.data.slip));
                                }
                            }).catch(e =>{
                                dispatch(setBetPlaceLoader(false));
                                dispatch(setBetSlip(e.data));
                                console.log(e);
                            }).finally(()=>{
                                dispatch(setBetLoader(false));
                                dispatch(setBetPlaceLoader(false));
                                resolve();
                            });

                            dispatch(setBetLoader(false));
                        } else {
                            dispatch(setBetLoader(false));
                            dispatch(setBetPlaceLoader(false));
                            console.log('setSlip error');
                        }
                    }
                }).catch(e =>{
                    dispatch(setBetPlaceLoader(false));
                    console.log('setSlip',e);
                })
            }
        })
    }
}

export function getMaxBet(slip, isOffline, reference_id){

    return dispatch => {

        return new Promise(resolve => {
            let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

            // dispatch(setBetLoader(true));

            if(!user_token){
                // dispatch(failedBetSlip());
                // dispatch(setBetLoader(false));
                // resolve();
            }else {
                axios({
                    url: `${API_URL}/bet/setSlip`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization':`${user_token}`
                    },
                    data: {slip}
                }).then( response => {
                    if (isOffline){
                        dispatch(setBetSlipShow(true));
                        dispatch(setBetSlip(response.data.data));
                        dispatch(setBetLoader(false));
                        resolve();
                    } else {
                        if(!response.data.error) {
                            maxBet(user_token, reference_id).then((response)=>{
                                let maxBet = response.data.data?.slip.amount;
                                if(!response.data.error) {
                                    if (reference_id) {
                                        const nBet = response.data.data.slip?.bets?.find(b => reference_id === b.reference_id);
                                        maxBet = nBet.amount;
                                    };
                                    dispatch(setBetSlip(response.data.data.slip));
                                    dispatch(setBetLoader(false));
                                    resolve(maxBet);
                                }
                            }).catch(e =>{
                                resolve();
                                console.log(e);
                            })
                        }
                    }
                }).catch(e =>{
                    console.log('setSlip',e);
                })
            }
        })
    }
}

function betPlace(user_token) {
    return new Promise((resolve, reject) => {
        axios({
            url: `${API_URL}/bet/placeBet`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'authorization':`${user_token}`
            },
            data: {}
        }).then(response => {
            resolve(response);
        }).catch(e => { reject(e.response.data) });
    })
}

function maxBet(user_token, reference_id) {
    return new Promise(resolve => {
        axios({
            url: `${API_URL}/bet/maxBet`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'authorization':`${user_token}`
            },
            data: { reference_id }
        }).then(response => {
            resolve(response);
        })
    })
}

export function setBetLoader(type = false) {
    return {
        type:BET_SLIP_LOADER,
        payload:type
    }
}

export function setBetPlaceLoader(type = false) {
    return {
        type:BET_PLACE_LOADER,
        payload:type
    }
}

export function setTicketShower(data = false) {
    return {
        type:SET_TICKET,
        payload:data
    }
}

import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../components/Header';
import Sidebar from 'containers/SideNav/index';
import Dashboard from './routes/dashboard';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
} from 'constants/ActionTypes';
import BetSlip from '../containers/Customizer/BetSlip';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';

class App extends React.Component {

  render() {
    const {match, drawerType} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar/>
        <div className="app-main-container">
          <div className='app-header app-header-horizontal'>
            <Header/>
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </main>
        </div>
        <BetSlip/>
      </div>
    );
  }
}


const mapStateToProps = ({settingsReducer}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settingsReducer;
  return {drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(App));

import {
    GET_FINANCE_DATA,
    GET_FINANCE_BY_DAY_DATA,
    GET_FINANCE_LOGS,
    SET_FINANCE_MODAL
} from '../constants/ActionTypes';

const INIT_STATE = {
    financeData:[],
    financeByDay:[],
    financeLogData:[],
    financeLogAmount:0,
    financeModal:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FINANCE_DATA: {
            return {...state, financeData:action.data}
        }
        case GET_FINANCE_BY_DAY_DATA: {
            return {...state, financeByDay:action.data}
        }
        case GET_FINANCE_LOGS: {
            return {...state, financeLogData: action.data, financeLogAmount: action.amount}
        }
        case SET_FINANCE_MODAL: {
            return {...state, financeModal:action.payload}
        }
        default:
            return state;
    }
}

import _ from "lodash";
import {
  liveSportsData,
  sportVerisons,
} from "app/routes/dashboard/routes/InPlay/data/sportsData";
import orderPrice from 'price-orderer';

const { SPORT_VERSION_1, SPORT_VERSION_2, SPORT_VERSION_3, SPORT_VERSION_4, SPORT_VERSION_5, SPORT_VERSION_6 } = sportVerisons;

export function changeMarketData(market) {
  market.m = Object.values(market.m).map((item) => {
    if (item.pr) {
      item.pr = Object.values(item.pr);
    }
    return item;
  });
}

export function createLiveLines(data) {
  data.forEach((event) => changeLiveLineSchema(event));
}

// compiling events...
export function changeLiveLineSchema(event, update = false) {
  if (!liveSportsData[event.si]) return event;
  if (!update && event.cn) {
    event.cn = event.cn
      .split(". ")
      .slice(-2)
      .join(" - ");
  }
  event.odds = {}
  if (sportVerisons.SPORT_VERSION_3.ids.includes(event.si)) {
    if (event.fs) {
      const arrayKeys = Number(event.si) === 110
          ? ["P1", "P2", "P3", "P4", "P5"]
          : ["S1", "S2", "S3", "S4", "S5", "S6", "S7"];

      const resultObject = {};
      for (let i = 0; i < arrayKeys.length; i++) {
        const fullFind = event.fs ? event.fs[arrayKeys[i]] : null;
        if (fullFind && fullFind.length) {
          resultObject[arrayKeys[i]] = fullFind.join(":");
        }
      }

      event.setsResultData = {
        T: event.fs.T ? event.fs.T.join(":") : "",
        TURN: event.fs.TURN || null,
      };
      if (Number(event.si) === 109) {
        event.setsResultData.points = event.fs.POINTS
          ? event.fs.POINTS.join(":")
          : "";
      }
      event.currentResultData = resultObject;
    } else {
      event.setsResultData = null;
      event.currentResultData = null;
    }
  }

  let method = getMarkFillMethod(event.si);

  const currentMarkets = event.m
    ? event.m.filter((odd) => liveSportsData[event.si].codes.includes(odd.mc))
    : [];
    // if (event.si ===502){
    //   console.log('setxel mtav', method, currentMarkets)}
  event.liveOdds = method(currentMarkets);

  return event;
}

function fillEmptyPrices1x2_AH_OU(odds, singleVariant) {
  const { match, handicap, quarter } = SPORT_VERSION_1.prices
  const double = { pr:[ { a: false, n: '1X' }, { a: false, n: '12' }, { a: false, n: 'X2' } ] };
  // not exists
  if (!odds.length) return [match, handicap, quarter];

  let m1x2 = odds.find((item) => item.mc.includes('1x2'));
  let mAH = odds.find((item) => item.mc.includes('AH'));
  let mOU = odds.find((item) => item.mc.includes('OU'));
  let mDC = odds.find((item) => item.mc.includes('DC'));

  if (!m1x2) {
    m1x2 = match;
  } else if (m1x2 && (!m1x2.pr || !m1x2.pr.length)) {
    m1x2.pr = match.pr;
  }

  if (!mAH) {
    mAH = handicap;
  } else if (mAH && (!mAH.pr || !mAH.pr.length)) {
    mAH.pr = handicap.pr;
  }

  if (!mOU) {
    mOU = quarter;
  } else if (mOU && (!mOU.pr || !mOU.pr.length)) {
    mOU.pr = quarter.pr;
  }

  if (!mDC) {
    mDC = double;
  } else if (mDC && (!mDC.pr || !mDC.pr.length)) {
    mDC.pr = double.pr;
  }

  const p1 = m1x2.pr.find((item) => item.n === "1") || match.pr[0];
  const px = m1x2.pr.find((item) => item.n === "X") || match.pr[1];
  const p2 = m1x2.pr.find((item) => item.n === "2") || match.pr[2];

  m1x2.pr = [p1, px, p2];
  m1x2.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && m1x2.pr[index] === match.pr[index]
  );

  const pAH1 = mAH.pr.find((item) => item.n === "1") || handicap.pr[0];
  const pAH2 = mAH.pr.find((item) => item.n === "2") || handicap.pr[1];
  mAH.pr = [pAH1, pAH2];
  mAH.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mAH.pr[index] === handicap.pr[index]
  );

  const pOver = mOU.pr.find((item) => item.n === "Over") || quarter.pr[0];
  const pUnder = mOU.pr.find((item) => item.n === "Under") || quarter.pr[1];
  mOU.pr = [pOver, pUnder];
  mOU.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU.pr[index] === quarter.pr[index]
  );

  const p1x = mDC.pr.find((item) => item.n === "1X") || double.pr[0];
  const p12 = mDC.pr.find((item) => item.n === "12") || double.pr[1];
  const px2 = mDC.pr.find((item) => item.n === "X2") || double.pr[2];
  mDC.pr = [p1x, p12, px2];
  mDC.pr.forEach((item, index) =>!Object.keys(item).length && mDC.pr[index] === double.pr[index]);
  

  const markets = [m1x2, mAH, mOU];
  enableInactivePrices(markets);

  if (singleVariant === "1x2") {
    return [m1x2];
  } else if (singleVariant === "AH") {
    return [mAH];
  } else if (singleVariant === "DC") {
    return [mDC];
  }

  return markets;
}

function fillEmptyPricesBasketball(odds) {
  const { match, total, quarter } = SPORT_VERSION_2.prices

  // not exists
  if (!odds.length) return [match, total, quarter];

  let mHA_OT = odds.find((item) => item.mc === "HA_OT");
  let mOU_OT = odds.find((item) => item.mc === "OU_OT");
  let mOU_Q1 = odds.find((item) => item.mc.substring(0, 4) === 'OU_Q');

  if (!mHA_OT) {
    mHA_OT = match;
  } else if (mHA_OT && (!mHA_OT.pr || !mHA_OT.pr.length)) {
    mHA_OT.pr = match.pr;
  }

  if (!mOU_OT) {
    mOU_OT = total;
  } else if (mOU_OT && (!mOU_OT.pr || !mOU_OT.pr.length)) {
    mOU_OT.pr = total.pr;
  }

  if (!mOU_Q1) {
    mOU_Q1 = quarter;
  } else if (mOU_Q1 && (!mOU_Q1.pr || !mOU_Q1.pr.length)) {
    mOU_Q1.pr = quarter.pr;
  }

  const p1 = mHA_OT.pr.find((item) => item.n === "1") || match.pr[0];
  const p2 = mHA_OT.pr.find((item) => item.n === "2") || match.pr[1];
  mHA_OT.pr = [p1, p2];
  mHA_OT.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mHA_OT.pr[index] === match.pr[index]
  );

  const pOver = mOU_OT.pr.find((item) => item.n === "Over") || total.pr[0];
  const pUnder = mOU_OT.pr.find((item) => item.n === "Under") || total.pr[1];
  mOU_OT.pr = [pOver, pUnder];
  mOU_OT.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU_OT.pr[index] === total.pr[index]
  );

  const pOverQuarter = mOU_Q1.pr.find((item) => item.n === "Over") || quarter.pr[0];
  const pUnderQuarter = mOU_Q1.pr.find((item) => item.n === "Under") || quarter.pr[1];
  mOU_Q1.pr = [pOverQuarter, pUnderQuarter];
  mOU_Q1.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU_Q1.pr[index] === quarter.pr[index]
  );

  const markets = [mHA_OT, mOU_OT, mOU_Q1];
  enableInactivePrices(markets);

  return markets;
}

function fillEmptyPricesTableTennis(odds) {
  const { match, total, handicap } = SPORT_VERSION_3.prices

  // not exists
  if (!odds.length) return [match, handicap, total];
  let mHA = odds.find((item) => item.mc === "HA");
  let mOU = odds.find((item) => item.mc === "OU" || item.mc === "OU_G");
  let mAH = odds.find((item) => item.mc === "AH" || item.mc === "AH_G");

  if (!mHA) {
    mHA = match;
  } else if (mHA && (!mHA.pr || !mHA.pr.length)) {
    mHA.pr = match.pr;
  }

  if (!mOU) {
    mOU = total;
  } else if (mOU && (!mOU.pr || !mOU.pr.length)) {
    mOU.pr = total.pr;
  }

  if (!mAH) {
    mAH = handicap;
  } else if (mAH && (!mAH.pr || !mAH.pr.length)) {
    mAH.pr = handicap.pr;
  }

  const p1 = mHA.pr.find((item) => item.n === "1") || match.pr[0];
  const p2 = mHA.pr.find((item) => item.n === "2") || match.pr[1];
  mHA.pr = [p1, p2];
  mHA.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mHA.pr[index] === match.pr[index]
  );

  const pOver = mOU.pr.find((item) => item.n === "Over") || total.pr[0];
  const pUnder = mOU.pr.find((item) => item.n === "Under") || total.pr[1];
  mOU.pr = [pOver, pUnder];
  mOU.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU.pr[index] === total.pr[index]
  );

  const pAH1 = mAH.pr.find((item) => item.n === "1") || handicap.pr[0];
  const pAH2 = mAH.pr.find((item) => item.n === "2") || handicap.pr[1];
  mAH.pr = [pAH1, pAH2];
  mAH.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mAH.pr[index] === handicap.pr[index]
  );

  const markets = [mHA, mAH, mOU];
  enableInactivePrices(markets);

  return markets;
}

function fillEmptyPricesBasketball3x3(odds) {
  const { match, total, handicap } = SPORT_VERSION_4.prices
  // not exists
  if (!odds.length) return [match, handicap, total];

  let mHA = odds.find((item) => item.mc === "HA_OT");
  let mAH = odds.find((item) => item.mc === "AH_OT");
  let mOU = odds.find((item) => item.mc === "OU_OT");

  if (!mHA) {
    mHA = match;
  } else if (mHA && (!mHA.pr || !mHA.pr.length)) {
    mHA.pr = match.pr;
  }

  if (!mOU) {
    mOU = total;
  } else if (mOU && (!mOU.pr || !mOU.pr.length)) {
    mOU.pr = total.pr;
  }

  if (!mAH) {
    mAH = handicap;
  } else if (mAH && (!mAH.pr || !mAH.pr.length)) {
    mAH.pr = handicap.pr;
  }

  const p1 = mHA.pr.find((item) => item.n === "1") || match.pr[0];
  const p2 = mHA.pr.find((item) => item.n === "2") || match.pr[1];
  mHA.pr = [p1, p2];
  mHA.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mHA.pr[index] === match.pr[index]
  );

  const pOver = mOU.pr.find((item) => item.n === "Over") || total.pr[0];
  const pUnder = mOU.pr.find((item) => item.n === "Under") || total.pr[1];
  mOU.pr = [pOver, pUnder];
  mOU.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU.pr[index] === total.pr[index]
  );

  const pAH1 = mAH.pr.find((item) => item.n === "1") || handicap.pr[0];
  const pAH2 = mAH.pr.find((item) => item.n === "2") || handicap.pr[1];
  mAH.pr = [pAH1, pAH2];
  mAH.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mAH.pr[index] === handicap.pr[index]
  );

  const markets = [mHA, mAH, mOU];
  enableInactivePrices(markets);

  return markets;
}

function fillEmptyPricesEsports(odds) {
  const { match, total, handicap } = SPORT_VERSION_5.prices

  // not exists
  if (!odds.length) return [match, handicap, total];

  let mHA = odds.find((item) => item.mc === 'HA');
  let mAH = odds.find((item) => item.mc.includes("AH") || item.mc.includes("1X2_M"));
  let mOU = odds.find((item) => item.mc.includes("OU"));

  if (!mHA) {
    mHA = match;
  } else if (mHA && (!mHA.pr || !mHA.pr.length)) {
    mHA.pr = match.pr;
  }

  if (!mOU) {
    mOU = total;
  } else if (mOU && (!mOU.pr || !mOU.pr.length)) {
    mOU.pr = total.pr;
  }

  if (!mAH) {
    mAH = handicap;
  } else if (mAH && (!mAH.pr || !mAH.pr.length)) {
    mAH.pr = handicap.pr;
  }

  const p1 = mHA.pr.find((item) => item.n === "1") || match.pr[0];
  const p2 = mHA.pr.find((item) => item.n === "2") || match.pr[1];
  mHA.pr = [p1, p2];
  mHA.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mHA.pr[index] === match.pr[index]
  );

  const pOver = mOU.pr.find((item) => item.n === "Over") || total.pr[0];
  const pUnder = mOU.pr.find((item) => item.n === "Under") || total.pr[1];
  mOU.pr = [pOver, pUnder];
  mOU.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mOU.pr[index] === total.pr[index]
  );

  const pAH1 = mAH.pr.find((item) => item.n === "1") || handicap.pr[0];
  const pAH2 = mAH.pr.find((item) => item.n === "2") || handicap.pr[1];
  mAH.pr = [pAH1, pAH2];
  mAH.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mAH.pr[index] === handicap.pr[index]
  );

  const markets = [mHA, mAH, mOU];
  enableInactivePrices(markets);

  return markets;
}

function fillEmptyPrices12(odds) {
  const { match } = SPORT_VERSION_5.prices

  // not exists
  if (!odds.length) return [match];

  let mHA = odds.find((item) => item.mc === 'HA');
  
  if (!mHA) {
    mHA = match;
  } else if (mHA && (!mHA.pr || !mHA.pr.length)) {
    mHA.pr = match.pr;
  }

  const p1 = mHA.pr.find((item) => item.n === "1") || match.pr[0];
  const p2 = mHA.pr.find((item) => item.n === "2") || match.pr[1];
  mHA.pr = [p1, p2];
  mHA.pr.forEach(
    (item, index) =>
      !Object.keys(item).length && mHA.pr[index] === match.pr[index]
  );
  const markets = [mHA];
  enableInactivePrices(markets);

  return markets;
}

function getMarkFillMethod(id) {
  if (SPORT_VERSION_1.ids.includes(id)) {
    return fillEmptyPrices1x2_AH_OU;
  } else if (SPORT_VERSION_2.ids.includes(id)) {
    return fillEmptyPricesBasketball;
  } else if (SPORT_VERSION_3.ids.includes(id)) {
    return fillEmptyPricesTableTennis;
  } else if (SPORT_VERSION_4.ids.includes(id)) {
    return fillEmptyPricesBasketball3x3;
  } else if (SPORT_VERSION_5.ids.includes(id)) {
    return fillEmptyPricesEsports;
  } else if (SPORT_VERSION_6.ids.includes(id)) {
    return fillEmptyPrices12;
  } else {
    return () => {};
  }
}

function enableInactivePrices(prices) {
  return prices.forEach((mark) => {
    if (!mark.a) {
      mark.pr.forEach((item) => (item.a = false));
    }
  });
}

export function singleEventDataChanger(data) {
  if (data.cn) {
    data.cn = data.cn
      .split(". ")
      .slice(-2)
      .join(" - ");
  }
  if (data && liveSportsData[data.si]) {
    data.odds = data.m;

    data.odds = _.sortBy(data.odds, "p");

    // filter odds closed market...
    data.odds = data.odds.filter((event) => event.a && event?.pr.length);

    // gorup by odds...
    data.odds = _.groupBy(data.odds, "mc");
    Object.values(data.odds).forEach((market) => {
      market.sort((a, b) => a.h - b.h);
      market = _.sortedUniqBy(market, 'h');
    });

    const all1x2 = Object.values(data.odds).filter(odd => odd[0].mc.includes('1x2'))
    fillSingleFootballPrices(data, all1x2, "1x2");

    const allDC = Object.values(data.odds).filter(odd => odd[0].mc.includes('DC'))
    fillSingleFootballPrices(data, allDC, "DC");
  }
}

export function distributeMarketsInCollection(data, collectionsMapC) {
  const collectionsMap = _.cloneDeep(collectionsMapC);
  const { m, si } = data;
  let modifiedOdds = {};
  m.forEach(m => {
    // odd.forEach(m => {
      const cData = collectionsMap[si] && collectionsMap[si][m.mi];  
      if (!cData) return
      if (!modifiedOdds[cData.title]) {
        modifiedOdds[cData.title] = cData;
      }
      if (!modifiedOdds[cData.title].markets[m.mc]) {
        modifiedOdds[cData.title].markets[m.mc] = {};
      }
      modifiedOdds[cData.title].markets[m.mc][
        (m?.pr.length === 2 && +m?.pr.find(p => p.n === '1')?.h) || m.h
      ] = { ...m, priority: cData.priority };
      modifiedOdds[cData.title].markets[m.mc][
        'priority'
      ] = cData.priority;
    // })
  });
  modifiedOdds = sortByKey(modifiedOdds, 'collectionPriority');
  Object.values(modifiedOdds).forEach((collection) => {
    collection.a = false;
    collection.markets = sortByKey(collection.markets, 'priority');
    Object.keys(collection.markets).forEach(key => {
      let pr = [];
      const markets = Object.values(collection.markets[key]); 
      const market = markets[0];
      Object.keys(collection.markets[key]).forEach(mKey => {
        if (mKey !== 'priority') {
          pr = [...pr, ...collection.markets[key][mKey].pr.filter(p => p.a)]
        }
      });
      market.a = !!markets.filter(m => m.a).length;
      market.pr = pr;
      collection.a = collection.a ? true : market.a && market.pr.length;
      collection.markets[key] = [_.cloneDeep(orderPrice(market))];
    }) 
  });
  data.odds = modifiedOdds;
  return modifiedOdds;
}

const sortByKey = (data, key) => {
  const sorted = Object.entries(data)
  .sort(([, a], [, b]) => a[key] - b[key])
  .reduce(
    (r, [k, v]) => ({
      ...r,
      [k]: v
    }),
    {}
  )
  return sorted;
}

const fillSingleFootballPrices = (data, markets, code) => {
  markets.forEach((market) => {
    if (market) {
      const newMarket = fillEmptyPrices1x2_AH_OU(market, code);
      data.odds[market[0].mc] = newMarket;
    }
  });
};

export const LIVE_SPORT_IDS = {
  FOOTBALL: 501,
  ICE_HOCKEY: 502,
  TENNIS: 503,
  BASKETBALL: 504,
  VOLLEYBALL: 505,
  HANDBALL: 506,
  AMERICAN_FOOTBALL: 507,
  TABLE_TENNIS: 509,
  SNOOKER: 516,
  DARTS: 518,
  FUTSAL: 519,
  CRICKET: 526,
  E_SPORTS: 577,
  CYBER_FOOTBALL: 578,
  CYBER_BASKETBALL: 579,
  CYBER_TENNIS: 580,
  CYBER_HOCKEY: 581,
  BASKETBALL_3x3: 583,
};

// export const LIVE_SPORT_ALL_IDS = {
//   FOOTBALL: 50,
//   ICE_HOCKEY: 51,
//   TENNIS: 52,
//   BASKETBALL: 53,
//   VOLLEYBALL: 54,
//   HANDBALL: 55,
//   BASEBALL: 56,
//   BOXING: 57,
//   FIELD_HOCKEY: 58,
//   BEACH_VOLLEYBALL: 59,
//   BEACH_FOOTBALL: 60,
//   RUGBY_UNION: 61,
//   SNOOKER: 62,
//   AMERICAN_FOOTBALL: 63,
//   WATER_POLO: 64,
//   FUTSAL: 65,
//   CHESS: 66,
//   BANDY: 67,
//   DARTS: 69,
//   TABLE_TENNIS: 70,
//   AUSSIE_RULES: 71,
//   CURLING: 72,
//   FORMULA_1: 73,
//   CRICKET: 75,
//   GOLF: 77,
//   BADMINTON: 78,
//   FLOORBALL: 82,
//   COMBAT_SPORTS: 90,
//   LACROSSE: 92,
//   BOWLS: 93,
//   RUGBY_LEAGUE: 94,
//   E_SPORTS: 95,
//   SQUASH: 96,
//   HORSE_RACING: 97,
//   SOFTBALL: 98,
//   GAELIC_FOOTBALL: 99,
//   SUMO: 100,
//   NETBALL: 100,
//   CYBER_FOOTBALL: 107,
//   CYBER_BASKETBALL: 108,
//   CYBER_TENNIS: 109,
//   CYBER_HOCKEY: 110,
//   BEACH_HANDBALL: 111,
//   NASCAR: 113,
//   INDY: 115,
//   KABADDI: 116,
//   SEPAK_TAKRAW: 117,
//   BAITHLON: 118,
//   SKI_JUMPING: 119,
//   CYBER_VOLLEYBALL: 120,
//   CYBER_HANDBALL: 121,
//   CYBER_BASEBALL: 122,
//   CYBER_COMBAT_SPORTS: 123,
//   CYBER_AUSSIE_RULES: 124,
//   E_RUGBY: 125,
//   ALPINE_SKIING: 126,
//   C_C_SKIING: 127,
// };
import socketIoClient from 'socket.io-client'
import {LIVE_SOCKET_URL} from '../util/configs';

import {
    LIVE_SOCKET_ON,
    LIVE_SOCKET_EMIT,
    LIVE_SOCKET_OFF
} from '../constants/ActionTypes';
let socket = {} || socketIoClient(LIVE_SOCKET_URL,{ path: '/socket.tv',transports: ['websocket','polling']});

export const liveSocketOn = (message, callback) => {
    return dispatch => {
        socket.on('' + message, (message) => {
            try {
                if(message[1] && typeof message[1] !== 'object'){
                    message[1] = JSON.parse(message[1]);
                }

                callback(message);

                dispatch({
                    type:LIVE_SOCKET_ON,
                    payload:message
                })
            } catch (e) {
                dispatch(liveSocketOff(message));
                console.log(e,'error');
            }
        });
    }
};
export const liveSocketOff = (message) => {
    return dispatch => {
        socket.removeListener(message);
        dispatch({
            type:LIVE_SOCKET_OFF,
            payload:false
        })
    }
};
export const liveSocketEmit = (message,data) => {
    return dispatch => {
        socket.emit('' + message, data);

        dispatch({
            type:LIVE_SOCKET_EMIT
        })
    }
};

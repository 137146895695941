import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";
import {getBalanceLimit,getBetPrintInfo} from '../actions'

const gameTypes = {
    keno: {
        data: "SET_GAME_KENO_DATA",
        loader: "SET_LOADER_KENO",
        start:"CHANGE_KENO_START",
        bet:"SET_KENO_BET_PLACE"
    },
    dogs: {
        data: "SET_GAME_DOGS_DATA",
        loader: "SET_LOADER_DOGS",
        start:"CHANGE_DOGS_START",
        bet:"SET_DOGS_BET_PLACE"
    },
    kaboom: {
        data: "SET_GAME_KABOOM_DATA",
        loader: "SET_LOADER_KABOOM",
        start:"CHANGE_KABOOM_START",
        bet:"SET_KABOOM_BET_PLACE"
    },
    horses: {
        data: "SET_GAME_HORSES_DATA",
        loader: "SET_LOADER_HORSES",
        start:"CHANGE_HORSES_START",
        bet:"SET_HORSES_BET_PLACE"
    },
    wof: {
        data: "SET_GAME_WOF_DATA",
        loader: "SET_LOADER_WOF",
        start:"CHANGE_WOF_START",
        bet:"SET_WOF_BET_PLACE"
    },
};

export function getGameData(type) {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        dispatch({type: gameTypes[type].start});
        if (!user_token) {
            dispatch(setGameData(type))
        } else {
            axios({
                url: `${API_URL}/games/getCurrentGame`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data: {
                    type
                }
            }).then(gameData => {
                if (!gameData.data.error) {
                    axios({
                        url: `${API_URL}/games/getLastGames`,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `${user_token}`
                        },
                        data: {
                            type,
                            limit: type === "wof" ? 12 : 5
                        }
                    }).then(lastGameData => {
                        if (!lastGameData.data.error) {
                            dispatch(setGameData(type, gameData.data.data,lastGameData.data.data));
                        } else {
                            dispatch(setGameData(type))
                        }
                    }).catch(e => {
                        dispatch(setGameData(type))
                    });
                } else {
                    dispatch(setGameData(type))
                }
            }).catch(e => {
                dispatch(setGameData(type))
            })
        }
    }
}

export function setGameData(type, gameData = {},lastGameData = []) {
    return {
        type: gameTypes[type].data,
        gameData,
        lastGameData
    };
}

export function getVfData() {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if (!user_token) {
            dispatch(virtualFData())
        } else {
            axios({
                url: `${API_URL}/games/getVFData`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data: {}
            }).then(vFData => {
                if (!vFData.data.error) {
                    dispatch(virtualFData(vFData.data.data))
                } else {
                    dispatch(virtualFData())
                }
            }).catch(e => {
                console.log(e);
                dispatch(virtualFData())
            })
        }
    }
}

export function virtualFData(gameData = []) {
    return {
        type: "GET_VF_DATA",
        payload: gameData
    };
}

export function gameBetPlace(slip, type) {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        dispatch(setGameBetPlace([], type));
        dispatch(setGameBetPlaceLoader(true, type));

        if (!user_token) {
            dispatch(setGameBetPlace([],type));
            dispatch(setGameBetPlaceLoader(false, type));
        } else {

            let drawId = slip.bets[0].draw_id;
            let isValid = true;

            for (let i = 0; i < slip.bets.length; i+=1){
                if (Number(slip.bets[i].draw_id) !== Number(drawId)){
                    isValid = false;
                    break;
                }
            }

            if(isValid) {
                axios({
                    url: `${API_URL}/games/betPlace`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${user_token}`
                    },
                    data: {slip}
                }).then(setBetPlace => {
                    if (!setBetPlace.data.error) {
                        dispatch(getBalanceLimit());
                        dispatch(getBetPrintInfo({packageId:setBetPlace.data.data.packageId}));
                        dispatch(setGameBetPlace(setBetPlace.data.data,type));
                    } else {
                        dispatch(setGameBetPlace(setBetPlace.data.data,type));
                        dispatch(setGameBetPlaceLoader(false,type));
                    }
                }).catch(e => {
                    dispatch(setGameBetPlace([],type));
                    dispatch(setGameBetPlaceLoader(false,type));
                });
            }else {
                slip.errors.push({message:'Draw Is Invalid'});
                dispatch(setGameBetPlace(slip,type));
                dispatch(setGameBetPlaceLoader(false,type));
            }
        }
    }
}

export function setGameBetPlace(betPlaceData, type ) {
    return {
        type: gameTypes[type].bet,
        payload: betPlaceData
    };
}

export function setGameBetPlaceLoader(checker, type) {
    return {
        type: gameTypes[type].loader,
        payload: checker
    };
}

export function setLoaderGame(checker, type) {
    return dispatch => (
        dispatch(setGameBetPlaceLoader(checker,type))
    )
}



import axios from "axios";
import {API_URL} from "../util/configs";
import cookie from "react-cookies";

import {getBalanceLimit} from "../actions";

import {
    GET_FINANCE_DATA,
    GET_FINANCE_BY_DAY_DATA,
    GET_FINANCE_LOGS,
    SET_FINANCE_MODAL
} from '../constants/ActionTypes';


export function financeIn(data) {
    return dispatch => {

        return new Promise(resolve => {

            let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
            if (!user_token) {
                console.log('token Error');
                resolve();
            } else {
                axios({
                    url: `${API_URL}/finance/inputFinance`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${user_token}`
                    },
                    data
                }).then(result => {
                    dispatch(setFinanceModal(false));
                    dispatch(getBalanceLimit());
                    resolve();
                }).catch(e => {
                    console.log('Error',e);
                    resolve();
                })
            }

        });
    }
}

export function financeOut(data) {
    return dispatch => {

        return new Promise(resolve => {

            let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
            if (!user_token) {
                console.log('token Error');
                resolve();
            } else {
                axios({
                    url: `${API_URL}/finance/outputFinance`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${user_token}`
                    },
                    data
                }).then(result => {
                    dispatch(setFinanceModal(false));
                    dispatch(getBalanceLimit());
                    resolve();
                }).catch(e => {
                    console.log('Error',e);
                    resolve();
                })
            }

        });

    }
}


export function financeData(data) {
    return dispatch => {
        dispatch({type:GET_FINANCE_DATA,data:[]});
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        if (!user_token) {
            dispatch({type:GET_FINANCE_DATA,data:[]})
        } else {
            axios({
                url: `${API_URL}/finance/financeStatistic`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data
            }).then(result => {
                if (!result.data.error) {
                    dispatch({type:GET_FINANCE_DATA,data:result.data.data})
                } else {
                    dispatch({type:GET_FINANCE_DATA,data:[]})
                }
            }).catch(e => {
                dispatch({type:GET_FINANCE_DATA,data:[]})
            })
        }
    }
}

export function financeDataByDay(data) {
    return dispatch => {
        dispatch({type:GET_FINANCE_BY_DAY_DATA,data:[]});
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        if (!user_token) {
            dispatch({type:GET_FINANCE_BY_DAY_DATA,data:[]})
        } else {
            axios({
                url: `${API_URL}/finance/financeByDay`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data
            }).then(result => {
                if (!result.data.error) {
                    dispatch({type:GET_FINANCE_BY_DAY_DATA,data:result.data.data})
                } else {
                    dispatch({type:GET_FINANCE_BY_DAY_DATA,data:[]})
                }
            }).catch(e => {
                dispatch({type:GET_FINANCE_BY_DAY_DATA,data:[]})
            })
        }
    }
}

export function financeLogsGet() {
    return dispatch => {
        dispatch({type:GET_FINANCE_LOGS,data:[]});
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;
        if (user_token) {
            axios({
                url: `${API_URL}/finance/logsBalance`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${user_token}`
                },
                data:{}
            }).then(result => {
                if (!result.data.error) {
                    dispatch({type:GET_FINANCE_LOGS,data:result.data.data.data, amount:result.data.data.amount})
                } else {
                    dispatch({type:GET_FINANCE_LOGS,data:[], amount:0})
                }
            }).catch(e => {
                dispatch({type:GET_FINANCE_LOGS,data:[], amount:0})
            })
        }
    }
}

export function setFinanceModal(data) {
    return {
        type:SET_FINANCE_MODAL,
        payload:data
    }
}





import {
    GET_STATISTICS_DATA
} from '../../constants/ActionTypes';

const INIT_STATE = {
    data:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STATISTICS_DATA: {
            return {...state,data:action.payload};
        }
        default:
            return state;
    }
}

import {
    SET_PAYOUTS_DATA
} from '../../constants/ActionTypes';

const INIT_STATE = {
    payoutsData:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAYOUTS_DATA: {
            return {...state, payoutsData:action.payload };
        }
        default:
            return state;
    }
}

import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  PM_USER_SIGN_IN_SUCCESS,
  PM_USER_SIGN_OUT_SUCCESS,
  PM_USER_INFO,
  PM_AUTH_LOADER,
  PM_USER_SIGN_IN_FAIL,
  PM_USER_EMPLOYEE,
  PM_USER_EMPLOYEE_SUCCESS,
  CLEAR_EMPLOYEE_MESSAGE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from "../constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  authLoader:false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: null,
  employeeUserSuccess:false,
  employeeMessage:''
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PM_USER_SIGN_IN_SUCCESS: {
      return {...state, authUser:action.payload, loader: false, authLoader:false,}
    }
    case PM_USER_SIGN_IN_FAIL: {
      return {...state, showMessage:true, alertMessage: action.payload, loader: false, authLoader:false,}
    }
    case PM_USER_SIGN_OUT_SUCCESS: {
      return {...state, authUser:null, loader: false, authLoader:false,}
    }
    case PM_USER_INFO: {
      return {...state, authUser:action.payload, loader: false, authLoader:false,}
    }
    case PM_AUTH_LOADER: {
      return {...state, authLoader:true,}
    }
    case PM_USER_EMPLOYEE: {
      return {...state, authUser:action.payload.employeeData,employeeMessage:action.payload.message}
    }
    case PM_USER_EMPLOYEE_SUCCESS: {
      return {...state, employeeUserSuccess:action.payload /*, ...(action.payload ? {} : {employeeMessage:''} )*/}
    }
    case CHANGE_PASSWORD_FAIL: {
      return {...state, showMessage:true, alertMessage: action.payload, loader: false, authLoader:false}
    }
    case CLEAR_EMPLOYEE_MESSAGE: {
      return {...state, showMessage: false, employeeMessage:''}
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        authLoader:false,
        authUser: {
          ...state.authUser,
          sp_reset: 0
        } 
      }
    }
    // case SIGNUP_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    // case SIGNIN_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    // case INIT_URL: {
    //   return {
    //     ...state,
    //     initURL: action.payload
    //   }
    // }
    // case SIGNOUT_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     authUser: null,
    //     initURL: '/app/dashboard/crypto',
    //     loader: false
    //   }
    // }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
        authLoader:false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
        authLoader:false
      }
    }
    // case SIGNIN_GOOGLE_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    // case SIGNIN_FACEBOOK_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    // case SIGNIN_TWITTER_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    // case SIGNIN_GITHUB_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     loader: false,
    //     authUser: action.payload
    //   }
    // }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}

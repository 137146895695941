import {
    SET_SEARCH_DATA,
    SET_SEARCH_LOADER,
} from '../constants/ActionTypes';

const INIT_STATE = {
    searchData:{},
    loader:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH_DATA: {
            return {...state,searchData:action.payload,loader:false}
        }
        case SET_SEARCH_LOADER: {
            return {...state,loader:true, searchData:{}}
        }
        default:
            return state;
    }
}

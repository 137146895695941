import axios from 'axios'
import {API_URL} from '../util/configs';
import cookie from "react-cookies";

import {
    SET_BALANCE_LIMIT
} from '../constants/ActionTypes';

export const getBalanceLimit = () => {
    return dispatch => {
        let user_token = cookie.select()['pm-token'] ? cookie.select()['pm-token'] : false;

        if(!user_token){
            dispatch(setBalanceLimit())
        }else {
            axios({
                url: `${API_URL}/account/getBalanceLimit`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':`${user_token}`
                },
                data: {}
            }).then(response => {
                if (!response.data.data.error){
                    dispatch(setBalanceLimit(response.data.data))
                } else {
                    dispatch(setBalanceLimit())
                }
            }).catch(e =>{
                dispatch(setBalanceLimit())
            })
        }
    }
};

export const setBalanceLimit = (data=[]) => {
    return {
        type:SET_BALANCE_LIMIT,
        payload:data
    }
};
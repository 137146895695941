import {
    GET_PAYOUT_TICKET_DATA,
    MAKE_PAYOUT_TICKET,
    CLEAR_PAYOUT_TICKET_DATA,
    DISABLE_BUTTON_PAYOUT_TICKET
} from '../constants/ActionTypes';

const INIT_STATE = {
    ticketData:{},
    result:null,
    disableButton:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAYOUT_TICKET_DATA: {
            return {...state, ticketData:action.data,result:action.result}
        }
        case MAKE_PAYOUT_TICKET: {
            return {...state, result:action.data}
        }
        case DISABLE_BUTTON_PAYOUT_TICKET: {
            return {...state, disableButton:!state.disableButton}
        }
        case CLEAR_PAYOUT_TICKET_DATA: {
            return {...state, ticketData:{}, result:null}
        }
        default:
            return state;
    }
}
import {
    WEBSITE_USER_INFO,
    WEBSITE_WITHDRAW,
    SET_GAME_ZONES,
    SET_GAME_ZONE_USER,
    WEBSITE_TICKET_OFFLINE
} from '../constants/ActionTypes';

const INIT_STATE = {
    userData:{},
    transaction:{},
    gameZones:[],
    gameZoneUser:{},
    ticket:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WEBSITE_USER_INFO: {
            return { ...state, userData:action.payload }
        }
        case WEBSITE_WITHDRAW: {
            return { ...state, transaction:action.payload }
        }
        case WEBSITE_TICKET_OFFLINE: {
            return { ...state,ticket:action.payload }
        }
        case SET_GAME_ZONES: {
            return { ...state,gameZones:action.payload }
        }
        case SET_GAME_ZONE_USER: {
            return { ...state,gameZoneUser:action.payload }
        }
        default:
            return state;
    }
}

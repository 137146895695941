import {
    LIVE_SOCKET_ON,
    LIVE_SOCKET_EMIT,
    LIVE_SOCKET_OFF
} from '../constants/ActionTypes';

const INIT_STATE = {
    socketData:[],
    typeName:''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIVE_SOCKET_ON: {
            return { ...state, socketData:action.payload[1],typeName:action.payload[0] }
        }
        case LIVE_SOCKET_EMIT: {
            return { ...state }
        }
        case LIVE_SOCKET_OFF: {
            return { ...state,socketData:action.payload }
        }
        default:
            return state;
    }
}
import {
    SET_KASSAS_DATA
} from '../../constants/ActionTypes';

const INIT_STATE = {
    kassasData:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_KASSAS_DATA: {
            return {...state, kassasData:action.payload };
        }
        default:
            return state;
    }
}

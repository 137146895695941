import { LIVE_SPORT_IDS } from "app/routes/dashboard/routes/InPlay/constants/ids";
import { createAction, createReducer } from "helpers/redux";
import { SET_MARKET_COLLECTIONS } from "constants/ActionTypes";
import {
  changeLiveLineSchema,
  changeMarketData,
  createLiveLines,
  singleEventDataChanger,
  distributeMarketsInCollection,
} from "app/routes/dashboard/routes/InPlay/helpers/liveDataChanger";

const allIds = Object.values(LIVE_SPORT_IDS);
const initialSports = allIds.reduce((obj, b) => {
  obj[b] = [];
  return obj;
}, {});

// ACTION TYPES
const SET_LIVE_DATA = "SET_LIVE_DATA";
const RESET_LIVE_DATA = "RESET_LIVE_DATA";
const TOGGLE_LIVE_ACTIVE_TAB = "TOGGLE_LIVE_ACTIVE_TAB";
const UPDATE_LIVE_EVENT = "UPDATE_LIVE_EVENT";
const RESET_LIVE_EVENT = "RESET_LIVE_EVENT";
const ADD_LIVE_EVENT = "ADD_LIVE_EVENT";
const REMOVE_LIVE_EVENT = "REMOVE_LIVE_EVENT";
const SET_ACTIVE_EVENT = "SET_ACTIVE_EVENT";
const SET_ACTIVE_EVENT_DATA = "SET_ACTIVE_EVENT_DATA";
const UPDATE_ACTIVE_EVENT = "UPDATE_ACTIVE_EVENT";

// ACTIONS
export const setMarketCollections = createAction(SET_MARKET_COLLECTIONS);
export const setLiveData = createAction(SET_LIVE_DATA);
export const resetLiveData = createAction(RESET_LIVE_DATA);
export const toggleLiveActiveTab = createAction(TOGGLE_LIVE_ACTIVE_TAB);
export const updateLiveEvent = createAction(UPDATE_LIVE_EVENT);
export const resetLiveEvent = createAction(RESET_LIVE_EVENT);
export const addLiveEvent = createAction(ADD_LIVE_EVENT);
export const removeLiveEvent = createAction(REMOVE_LIVE_EVENT);
export const setActiveEvent = createAction(SET_ACTIVE_EVENT);
export const setActiveEventData = createAction(SET_ACTIVE_EVENT_DATA);
export const updateActiveEvent = createAction(UPDATE_ACTIVE_EVENT);

// REDUCER
const initialState = {
  activeTab: null,
  activeEventId: null,
  activeEvent: {},
  sports: initialSports,
  marketCollections: {},
};

export const inPlayReducer = createReducer(initialState, (state, { value }) => ({
    [SET_LIVE_DATA]: () => {
      value.forEach((event) => {
        if (event.m) {
          changeMarketData(event);
        }
      });

      createLiveLines(value);

      const sports = allIds.reduce((obj, b) => {
        obj[b] = value
          .filter((item) => item.si === b)
          .sort((a, b) => a.lp - b.lp);
        return obj;
      }, {});

      let activeTab = null;
      let activeEvent = {};
      let activeEventId = null;

      for (let i = 0; i < allIds.length; i++) {
        if (sports[allIds[i]].length) {
          activeTab = allIds[i];
          break;
        }
      }

      if (activeTab) {
        activeEvent = sports[activeTab][0];
        activeEventId = sports[activeTab][0].ei;
      }
      return {
        ...state,
        activeTab,
        sports,
        activeEvent,
        activeEventId,
      };
    },
    [RESET_LIVE_DATA]: () => initialState,
    [UPDATE_LIVE_EVENT]: () => {
      if (!state.sports[value.si]) return state;
      if (value.m) {
        changeMarketData(value);
      }

      const copySport = [...state.sports[value.si]];
      const currentEventId = copySport.findIndex(
        (item) => item.ei === value.ei
      );

      if (currentEventId === -1) return state;
      copySport[currentEventId] = { ...copySport[currentEventId], ...value };

      if (value.m && state.sports[value.si][currentEventId].m) {
        let prevMarkets = {};
        state.sports[value.si][currentEventId].m.forEach((m => prevMarkets[m.mc] = m));
        value.m.forEach((market) => {
          if (prevMarkets[market.mc] && +prevMarkets[market.mc].h === +market.h) {
            const { pr, ...rest } = market
            prevMarkets[market.mc] = {
              ...prevMarkets[market.mc],
              ...rest,
            };
            if (pr) {
              market.pr.forEach((price) => {
                const existPriceId = prevMarkets[market.mc].pr.findIndex(
                  (item) => item.n === price.n
                );
                const prevMarket = prevMarkets[market.mc];
                if (existPriceId !== -1 && prevMarket && price) {
                  let action = "";
                  if (market.a && prevMarket && prevMarket.pr[existPriceId] && prevMarket.pr[existPriceId].fv 
                    && price && price.fv && price.fv >= 1 && price.a) {
                    if (prevMarket.pr[existPriceId].fv > price.fv) {
                      action = "minus";
                    } else if (prevMarket.pr[existPriceId].fv < price.fv) {
                      action = "plus";
                    }
                  }
                  prevMarkets[market.mc].pr[existPriceId] = {
                    ...prevMarkets[market.mc].pr[existPriceId],
                    ...price,
                    action: action || prevMarkets[market.mc].pr[existPriceId].action,
                  };
                } else {
                  prevMarkets[market.mc].pr = [
                    ...prevMarkets[market.mc].pr,
                    ...price,
                  ];
                }
              });
            }
          } 
          else {
            prevMarkets[market.mc] = market;
          }
        });
        copySport[currentEventId].m = Object.values(prevMarkets);
      }

      if (value.m || value.fs) {
        changeLiveLineSchema(copySport[currentEventId], true);
      }

      value.lp && copySport.sort((a, b) => a.lp - b.lp);
      return {
        ...state,
        sports: {
          ...state.sports,
          [value.si]: copySport,
        },
      };
    },
    [RESET_LIVE_EVENT]: () => {
      if (!state.sports[value.si] || !value.m) return state;

      const currentEventId = state.sports[value.si].findIndex( item => item.ei === value.ei );
      
      if (currentEventId === -1) return state;

      changeMarketData(value);
      const copySport = [...state.sports[value.si]];
      copySport[currentEventId] = { ...copySport[currentEventId], ...value };

      const currentEventMarkets = state.sports[value.si][currentEventId].m

      if(value.m.length !== currentEventMarkets.length){
        currentEventMarkets.forEach((item) => {
          const foundMarket = value.m.find(market => market.mc === item.mc)
          !foundMarket && copySport[currentEventId].m.push(item)
        })
      }

      changeLiveLineSchema(copySport[currentEventId]);

      return {
        ...state,
        sports: {
          ...state.sports,
          [value.si]: copySport,
        },
      };
    },
    [ADD_LIVE_EVENT]: () => {
      if (!state.sports[value.si]) return state
      if (value.m) {
        changeMarketData(value);
      }

      changeLiveLineSchema(value);
      distributeMarketsInCollection(value, state.marketCollections);
      const currentSport = [...state.sports[value.si], value].sort(
        (a, b) => a.lp - b.lp
      );
      if (!state.activeTab) {
        // add first event
        return {
          ...state,
          activeTab: value.si,
          activeEventId: value.ei,
          activeEvent: value,
          sports: {
            ...state.sports,
            [value.si]: currentSport,
          },
        };
      }
      return {
        ...state,
        sports: {
          ...state.sports,
          [value.si]: currentSport,
        },
      };

    },
    [REMOVE_LIVE_EVENT]: () => {
      if (!state.sports[value.si]) return state;
      const currentSport = state.sports[value.si].filter(
        (item) => item.ei !== value.ei
      );
      const sports = { ...state.sports, [value.si]: currentSport };

      //remove active event
      if (state.activeEventId === value.ei) {
        let activeEventId = null;
        let activeEvent = {};
        let activeTab = null;
        if (currentSport.length) {
          activeTab = state.activeTab;
          activeEventId = currentSport[0].ei;
          activeEvent = currentSport[0];
        } else {
          for (let i = 0; i < allIds.length; i++) {
            if (sports[allIds[i]].length) {
              activeTab = allIds[i];
              activeEventId = sports[allIds[i]][0].ei;
              activeEvent = sports[allIds[i]][0];
              break;
            }
          }
        }
        return {
          ...state,
          activeTab,
          activeEventId,
          activeEvent,
          sports,
        };
      }

      return {
        ...state,
        sports,
      };
    },
    [TOGGLE_LIVE_ACTIVE_TAB]: () => ({
      ...state,
      activeTab: value,
      activeEventId: state.sports[value][0].ei,
      activeEvent: state.sports[value][0],
    }),
    [SET_ACTIVE_EVENT]: () => {
      if (value.m) {
        changeMarketData(value);
      }
      if(value.v) {
        value.oldV = value.v
      }
      value.odds = {};
      singleEventDataChanger(value);
      distributeMarketsInCollection(value, state.marketCollections);
      return { ...state, activeEventId: value.ei, activeEvent: value };
    },
    [SET_ACTIVE_EVENT_DATA]: () => {
      if (value.m) {
        changeMarketData(value);
      }
      if(value.v) {
        value.oldV = value.v
      }
      value.odds = {};
      singleEventDataChanger(value);
      distributeMarketsInCollection(value, state.marketCollections);
      return { ...state, activeEvent: value };
    },
    [UPDATE_ACTIVE_EVENT]: () => {
      if (state.activeEvent.ei !== value.ei) return state;
      if (value.m) {
        changeMarketData(value);
      }
      if(value.v && state.activeEvent.v) {
        state.activeEvent.oldV = state.activeEvent.v
      }

      const copyEvent = { ...state.activeEvent };
      copyEvent.odds = {}
      if (value.m && state.activeEvent.m) {
        // let prevMarkets = [...state.activeEvent.m];
        let prevMarkets = {};
        state.activeEvent.m.forEach((m) => prevMarkets[`${m.mc}-${m.h}`] = m);
        value.m.forEach((market) => {
          // const existMarketId = prevMarkets.findIndex(
          //   (item) => item.mc === market.mc && (
          //     +item.h === +market.h ||
          //      (market?.pr.length === 2 && +market?.pr.find(p => p.n === '1')?.h === +item.h)
          //   )
          // );
          const existMarket = prevMarkets[`${market.mc}-${market.h}`];
          if ((market.r || !market.a || !market.pr.length) && existMarket) {
            // prevMarkets.splice(existMarketId, 1);
            delete prevMarkets[`${market.mc}-${market.h}`];
          } else if (existMarket) {
            const {pr, ...rest} = market
            prevMarkets[`${market.mc}-${market.h}`] = {
              ...prevMarkets[`${market.mc}-${market.h}`],
              ...rest,
            };
            if (pr) {
              pr.forEach((price) => {
                const existPriceId = prevMarkets[`${market.mc}-${market.h}`].pr.findIndex(
                  (item) => item.n === price.n
                );
                const prevMarket = prevMarkets[`${market.mc}-${market.h}`];

                if((price.r || !price.a) && existPriceId !== -1) {
                  prevMarkets[`${market.mc}-${market.h}`].pr.splice(existPriceId, 1)
                } else if (existPriceId !== -1 && prevMarket && price) {
                    let action = "";
                    if ( market.a && prevMarket && prevMarket.pr[existPriceId] && prevMarket.pr[existPriceId].fv 
                      && price && price.fv && price.fv >= 1 && price.a) {
                      if (prevMarket.pr[existPriceId].fv > price.fv) {
                        action = "minus";
                      } else if (prevMarket.pr[existPriceId].fv < price.fv) {
                        action = "plus";
                      }
                    }
                    if(prevMarkets[`${market.mc}-${market.h}`]?.pr) {
                      prevMarkets[`${market.mc}-${market.h}`].pr[existPriceId] = {
                        ...prevMarkets[`${market.mc}-${market.h}`].pr[existPriceId],
                        ...price,
                        action: action || prevMarkets[`${market.mc}-${market.h}`].pr[existPriceId].action,
                      };
                    }
                } else {
                  prevMarkets[`${market.mc}-${market.h}`].pr = [
                    price,
                    ...prevMarkets[`${market.mc}-${market.h}`].pr,
                  ];
                }
              });
            }
          } else {
            prevMarkets[`${market.mc}-${market.h}`] = market;
          }
        });
        copyEvent.m = Object.values(prevMarkets);
      }
      singleEventDataChanger(copyEvent);
      distributeMarketsInCollection(copyEvent, state.marketCollections);
      return {
        ...state,
        activeEvent: copyEvent,
      };
    },
    [SET_MARKET_COLLECTIONS]: () => {
      return {
        ...state,
        marketCollections: value
      }
    }
  })
);

import {
    SET_GAME_KENO_DATA,
    CHANGE_KENO_START,
    SET_KENO_BET_PLACE,
    SET_LOADER_KENO,
} from '../../constants/ActionTypes'

const INIT_STATE = {
    gameData: {},
    lastGameData: [],
    gameBetLoader:false,
    setBetPlace: false,
    readyStart: 'pending'
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_GAME_KENO_DATA: {
            return {...state, gameData: action.gameData,lastGameData: action.lastGameData,readyStart:'start'}
        }
        case SET_LOADER_KENO: {
            return {...state, gameBetLoader: action.payload}
        }
        case CHANGE_KENO_START: {
            return {...state, readyStart: 'pending',gameData: {},lastGameData: []}
        }
        case SET_KENO_BET_PLACE: {
            return {...state, setBetPlace: action.payload}
        }
        default:
            return state;
    }
}

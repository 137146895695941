import {combineReducers} from 'redux';
import settingsReducer from './Settings';
import authReducer from './Auth';
import preMatchesReducer from './PreMatches';
import liveReducer from './Live';
import { inPlayReducer } from './ducks/inPlayDuck';
import betSlipReducer from './BetSlip';
import websiteReducer from './Website';
import financeReducer from './Finance';
import payoutTicketReducer from './PayoutTicket';

// Games
import kenoReducer from './games/KenoGame';
import dogsReducer from './games/DogsGame';
import kaboomReducer from './games/KaboomGame';
import horsesReducer from './games/HorsesGame';
import wofReducer from './games/WofGame';
import vfReducer from './games/VFGame';

//Other
import downloadReducer from './other/Downloads';
import resultsReducer from './other/Results';
import searchReducer from './Search';
import balanceLimitReducer from './BalanceLimit';
import betsReducer from './other/Bets';
import payoutsReducer from './other/Payouts';
import kassasReducer from './other/Kassas';

// import {connectRouter} from 'connected-react-router'
// router: connectRouter(history),

export default () => combineReducers({
    settingsReducer,
    authReducer,
    preMatchesReducer,
    liveReducer,
    inPlayReducer,
    betSlipReducer,
    dogsReducer,
    kaboomReducer,
    horsesReducer,
    wofReducer,
    kenoReducer,
    vfReducer,
    downloadReducer,
    resultsReducer,
    websiteReducer,
    searchReducer,
    balanceLimitReducer,
    betsReducer,
    kassasReducer,
    payoutsReducer,
    financeReducer,
    payoutTicketReducer
});

import React, {Component} from 'react';

import {Modal, ModalHeader} from "reactstrap";

import Barcode from "react-barcode";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { isNumber, fixByRule } from '../../util'; 

import './main.css';

const signatureTexts = ['ከፋይ ሰራተኛ ስም', 'የተከፋይ ስም', 'የተከፋይ ስልክ', 'የተከፋይ ፊርማ']
const bwanabetTicketText = `
    By placing a bet, the client has read and understands and accepts the Bwana Bet Terms and Conditions of play,
    which can be found in full at www.bwanabet.co.zm Paying tickets are valid for a maximum of 30 days after the last valid
    match has played. Client is responsible for checking their ticket and money before leaving the counter.
    Management does not accept any responsibility for incorrect tickets or money after client has left the counter.
    Management reserves the right to cancel and refund any bets, at any time`;

const marketNamesMap = {
    "M" : "Who will finish 1st",
    "N" : "Who will finish 1st or 2nd",
    "O" : "Who will finish 1st or 2nd or 3rd",
    "MS" : "Who will not finish 1st",
    "NS" : "Who will not finish 1st or 2nd",
    "OS" : "Who will not finish 1st or 2nd or 3rd"
};


class Index extends Component {
    openPrinter = (check) => {
        if(check) {
            setTimeout(()=> {
                // if (this.props.countrySettings && this.props.countrySettings.logo && this.props.countrySettings.logo.print){
                    window.print();
                    this.props.toggleModal()
                // }
            },200);
        }
    };

    calcPackageID = (id) => {
        id = id.toString();
        const splited = id.split('-');
        return (splited[0] + "-" + splited[1][0] + "XXX" + id.slice(-3));
    };

    numberWithCommas = (x,isFix = false) => {
        x = isFix ? Number(x).toFixed() : x;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    rawEvent = (event,key) => {
        const names = event.name.split(' V ');
        const team1 = names[0].substring(0,11);
        const team2 = names[1].substring(0,11);
        let resultString = '';
        for(let i = 0; i < event.choices.length; i++){
            if(event.choices[i].selected){
                resultString = resultString + ' ' + this.correctTotoBet(event.choices[i].choice_name);
            }
        }
        return (
            <div>
                {(key < 10 ? '0' + key : key) + ' | ' + team1.padEnd(11, ' ') + ' - ' + team2.padEnd(11, ' ') + ' | ' + resultString}
            </div>
        )

    };

    correctTotoBet = (name) => {
        if(name){
            let elem = '';
            switch (Number(name)) {
                case 2:
                    elem = 'X';
                    break;
                case 3:
                    elem = '2';
                    break;
                default:
                    elem = '1';
                    break;
            }
            return elem;
        }
        return '';
    };

    selectionFunc = (type,event) => {
        if(type !== 'dogs' && type !== 'horses'){
            return event;
        }else {
            const splited = event.split("-");
            let result ;
            if(splited[2] === 'MN') {
                result = "1st and 2nd place order (" + splited[3] + " then " + splited[4] + ")";
            }else {
                result = marketNamesMap[splited[2]];
            }
            return result;
        }
    };

    betSlipInfo = (dataStatistics,isGame) => {
        const {globalSettings,countrySettings} = this.props;
        // console.log(globalSettings && globalSettings.betting && globalSettings.betting.Tax)
        const currency = globalSettings.betting ? globalSettings.betting.ExchangeRate : '';
        if(globalSettings && globalSettings.label && [ 'VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project)) {
            return (
                <div>
                    <div className={'ticket-right-left-pull bold-font-total after-underline'}>Deposit: <span>{this.numberWithCommas(dataStatistics.beforeAmountTax || dataStatistics.amount) + ' ' + currency}</span></div>
                    <div className={'ticket-right-left-pull after-underline'}>VAT 15%: <span>{(dataStatistics.beforeAmountTax - dataStatistics.total_amount).toFixed(2) + ' ' + currency}</span></div>
                    <div className={'ticket-right-left-pull after-underline'}>Placed Bet: <span>{this.numberWithCommas(dataStatistics.total_amount) + ' ' + currency}</span></div>
                    { dataStatistics.type === 1 ? <div className={'ticket-right-left-pull after-underline'}>Total Odds: <span>{fixByRule(dataStatistics.total_odds)}</span></div> : ''}

                    {!isGame && dataStatistics.possible_bonus && ([ 'VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project)
                    ? <div className={'ticket-right-left-pull bold-font-total after-underline'}>Multi bonus: <span>{this.numberWithCommas(dataStatistics.possible_bonus) + ' ' + currency}</span></div>
                    : null)}

                    <div className={'ticket-right-left-pull after-underline'}>WIN: <span>{dataStatistics.possible_winning ? this.numberWithCommas(dataStatistics.possible_winning.toFixed(2),true) + ' ' + currency : ''}</span></div>
                    {!isGame && dataStatistics.possible_tax && <div className={'ticket-right-left-pull after-underline'}>15% Income Tax: <span>{this.numberWithCommas(dataStatistics.possible_tax) + ' ' + currency}</span></div>}
                    <div className={'ticket-right-left-pull bold-font-total after-underline'}>Net Win/Payout: <span>{dataStatistics.total_winning ? this.numberWithCommas(dataStatistics.total_winning,true) : ''} {currency}</span></div>
                    <div style={{margin:'15px 15px 0px 15px'}}>
                        <div style={{textAlign: 'center',fontFamily: 'monospace', fontWeight: 'bold', fontSize:'20px',marginBottom:'10px'}}>
                            Please check your ticket before leaving, later disputes will not be considered.
                        </div>
                        <div style={{textAlign: 'center', fontSize:'17px'}}>Maximum possible win is: {this.numberWithCommas(globalSettings?.betting?.MaxWinSum || 350000)} ETB</div>
                        <div style={{textAlign: 'center', fontSize:'17px'}}>By placing the bet client agrees and accepts the rules and conditions.</div>
                        <div style={{textAlign: 'center', fontSize:'17px'}}>The rules and conditions are available here:</div>
                        <div style={{textAlign: 'center',fontFamily: 'monospace', fontWeight: 'bold', fontSize:'24px',marginTop:"10px"}}>
                            {countrySettings && countrySettings.logo && countrySettings.logo.web}
                        </div>
                    </div>
                </div>
            )
        } else if (globalSettings.label.project === 'BWANABET') {
             return (
                <div>
                    { dataStatistics.type === 1 ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>TOTAL ODDS: <span>{fixByRule(dataStatistics.total_odds)}</span></div> : ''}
                    <div className={'bold-font-total after-underline ticket-right-left-pull'}>TOTAL STAKE: <span>{`K ${this.numberWithCommas(dataStatistics.beforeAmountTax || dataStatistics.amount)}`}</span></div>
                    {/* {(dataStatistics.beforeAmountTax) && isNumber(dataStatistics.total_amount) ?
                    <div className={'bold-font-total after-underline'}>Stake after Tax: ={this.numberWithCommas(dataStatistics.total_amount)}=</div> : null
                    } */}
                    {isNumber(dataStatistics.possible_winning) ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>POSSIBLE WIN: <span>{`K ${dataStatistics.possible_winning ? this.numberWithCommas(dataStatistics.possible_winning.toFixed(2), false) : ''}`}</span></div> : null}
                    {!isGame && isNumber(dataStatistics.possible_bonus) ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>BONUS: <span>{`K ${this.numberWithCommas(dataStatistics.possible_bonus)}`}</span></div> : null}
                    {!isGame && isNumber(dataStatistics.possible_tax) ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>TAX: <span>{`K ${isNumber((dataStatistics.possible_tax) && this.numberWithCommas(dataStatistics.possible_tax)) || 0}`}</span></div> : null}
                    {!isGame && isNumber(dataStatistics.possible_extra_bonus) ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>WINNING BONUS: <span>{`K ${isNumber((dataStatistics.possible_extra_bonus) && this.numberWithCommas(dataStatistics.possible_extra_bonus)) || 0}`}</span></div> : null}
                    {isNumber(dataStatistics.total_winning) && isNumber(dataStatistics.possible_tax) ? <div className={'bold-font-total after-underline ticket-right-left-pull'}>MAX Possible WIN: <span>{`K ${dataStatistics.total_winning ? this.numberWithCommas(dataStatistics.total_winning,false) : ''}`}</span></div> : null}
                </div>
            )
        } else {
            return (
                <div>
                    <div className={'bold-font-total after-underline'}>Total: {this.numberWithCommas(dataStatistics.beforeAmountTax || dataStatistics.amount) + ' ' + currency}</div>
                    {(dataStatistics.beforeAmountTax) && isNumber(dataStatistics.total_amount) ?
                    <div className={'bold-font-total after-underline'}>Stake after Tax: ={this.numberWithCommas(dataStatistics.total_amount)}=</div> : null
                    }
                    { dataStatistics.type === 1 ? <div className={'bold-font-total after-underline'}>Total Odds: {fixByRule(dataStatistics.total_odds)}</div> : ''}
                    {isNumber(dataStatistics.possible_winning) ? <div className={'after-underline'}>Possible Winning: {dataStatistics.possible_winning ? this.numberWithCommas(dataStatistics.possible_winning.toFixed(2), globalSettings.label.project === 'MAVERIX' ? false : true) + ' ' + currency : ''}</div> : null}
                    {!isGame && isNumber(dataStatistics.possible_bonus) ? <div className={'after-underline'}>Possible Bonus: {this.numberWithCommas(dataStatistics.possible_bonus) + ' ' + currency}</div> : null}
                    {!isGame && isNumber(dataStatistics.possible_tax) ? <div className={'after-underline'}>
                        {globalSettings.label.project === 'PMBETZM' ? `Withholding Tax ${globalSettings && globalSettings.betting && globalSettings.betting.Tax ? globalSettings.betting.Tax + '%' : ''}` : 'Possible Tax'}: {this.numberWithCommas(dataStatistics.possible_tax) + ' ' + currency}</div> : null}
                    {isNumber(dataStatistics.total_winning) && isNumber(dataStatistics.possible_tax) ? <div className={'after-underline'}>Possible Total Winning: {dataStatistics.total_winning ? this.numberWithCommas(dataStatistics.total_winning,true) : ''} {currency}</div> : null}
                </div>
            )
        }
    };

    render() {
        let {isLogo,isToto,isWebsite,isBet,isPrintNow,isPrintView,
            dataStatistics, countrySettings, toggleModal,isStats,
            slipOpen, isGame,isCopy, ticketPrint ,userData, globalSettings} = this.props;
        dataStatistics.possible_tax = dataStatistics.possible_tax || null;
        dataStatistics.possible_bonus = dataStatistics.possible_bonus || null;
        const currency = globalSettings.betting ? globalSettings.betting.ExchangeRate : '';
        return (
            <div>
                {dataStatistics &&
                <Modal onOpened={this.openPrinter(isPrintNow)}  className="modal-box ticket-modal" fade={false} isOpen={slipOpen} toggle={toggleModal} style={{zIndex: 2600}}>
                    <ModalHeader className="modal-box-header bg-primary text-white">
                        {isWebsite && <span>Print Preview</span> }
                        <span>{isPrintNow ? 'Ticket Preview' : 'Ticket Info'}</span>
                        <i onClick={toggleModal} className="zmdi zmdi-close text-white"/>
                    </ModalHeader>


                    {isWebsite &&
                        <div className={`modal-box-content ticket-modal-content`}>
                            <span className={'modal-content-img-part'}>
                                {isLogo && countrySettings && countrySettings.logo &&
                                    <img className={'label-logo'} src={countrySettings.logo.print} alt={countrySettings.logo.alt}/>
                                }
                            </span>
                            {['PMBETTZ','VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project) ? 
                                <div className='modal-content-center-col '>
                                    {dataStatistics.ticketTitle ? 
                                        <span className='after-underline'>{dataStatistics.ticketTitle}</span> : null}
                                    {userData.employee_info ? 
                                        <div>Cashier 
                                            <span> {userData.employee_info && (userData.employee_info.first_name +' '+ userData.employee_info.last_name)}, {userData && userData.username}</span>
                                        </div> : null}
                                </div>: null
                            }
                            {dataStatistics.username ?
                                <div className={'ticket-right-left-pull after-underline bold'}>
                                   {['VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project) ? 
                                        <>
                                            Device: <span>{dataStatistics.username}</span> 
                                        </>
                                        : <span>Username {dataStatistics.username}</span>}
                                </div>
                                :
                                <>
                                    <span>Website {dataStatistics.phone ? 'Deposit' : 'Withdraw'}</span>
                                    {dataStatistics.phone ? <span>Phone: {dataStatistics.phone}</span> : <span>Transaction Code: {dataStatistics.code}</span>}
                                </>
                            }
                            {['VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project) ? 
                                <>
                                    <div className={'ticket-right-left-pull after-underline bold'}>
                                        {dataStatistics.opType === 'cashIn' ? 'Deposit amount' : 'Withdrawal Amount'}: <span>{dataStatistics.amount + ' ' + currency}</span>
                                    </div>
                                    {dataStatistics.vat ? 
                                        <>
                                            <div className={'ticket-right-left-pull after-underline bold'}>
                                                VAT {dataStatistics.vat}%: <span>{dataStatistics.vatAmount + ' ' + currency}</span>
                                            </div>
                                            <div className={'ticket-right-left-pull after-underline bold'}>
                                                Deposit after VAT: <span>{(dataStatistics.amount - dataStatistics.vatAmount).toFixed(2) + ' ' + currency}</span>
                                            </div>
                                            
                                        </> 
                                        : null
                                    }
                                    <div className={'ticket-right-left-pull after-underline bold'}>
                                        Date issued: <span>{dataStatistics.dt}</span>
                                    </div>
                                </> : 
                                <>
                                    <span>Amount: {dataStatistics.amount}</span>
                                    <span>Date issued: {dataStatistics.dt}</span>
                                </>
                                
                            }
                            
                        </div>
                    }

                    {isStats && (globalSettings.label.statisticEnabled || [ 'VAMOSETH', 'DASH'].includes(globalSettings.label.project)) &&
                    <div className={`modal-box-content ticket-modal-content dash`}>
                            <span className={'modal-content-img-part'}>
                                {isLogo && countrySettings && countrySettings.logo &&
                                <img className={'label-logo'} src={countrySettings.logo.print} alt={countrySettings.logo.alt}/>
                                }
                            </span>
                        <div>DAY: <span> {dataStatistics.day} </span></div>
                        <div>Print Time: <span> {dataStatistics.time} </span></div>
                        <br/>
                        <div>Cashier: <span> {userData.employee_info && (userData.employee_info.first_name +' '+ userData.employee_info.last_name)} </span></div>
                        <div>SHOP: <span> {userData.username} </span></div>
                        <br/>
                        {globalSettings.label.statisticEnabled 
                        ? <>
                        {globalSettings.label && ['JEGINA'].includes(globalSettings.label.project) ? 
                            <> 
                                <div>Number of deposits:            <span> {dataStatistics.deposit.count}   </span>        </div>
                                <div>Amount of deposits: 	        <span> {dataStatistics.deposit.sum}     </span>        </div>
                            </> : 
                            <>
                                <div>Balance Start:                 <span> {dataStatistics.balance.start}   </span>        </div>
                                <div>Balance End: 	                <span> {dataStatistics.balance.end}     </span>        </div>
                                <div>Number of tickets in:          <span> {dataStatistics.tickets.count}   </span>        </div>
                                <div>Total ticket in: 	            <span> {dataStatistics.tickets.sum}     </span>        </div>
                                <div>Number of paid tickets:        <span> {dataStatistics.paid.count}      </span>        </div>
                                <div>Total paid tickets:         	<span> {dataStatistics.paid.sum}        </span>        </div>
                                <div>Number of cancelled tickets:   <span> {dataStatistics.cb.count}        </span>        </div>
                                <div>Total cancelled tickets:   	<span> {dataStatistics.cb.sum}          </span>        </div>
                                <div>Number of deposits:            <span> {dataStatistics.deposit.count}   </span>        </div>
                                <div>Amount of deposits: 	        <span> {dataStatistics.deposit.sum}     </span>        </div>
                                <div>Number of withdrawals:         <span> {dataStatistics.withdraw.count}  </span>        </div>
                                <div>Amount of withdrawals:      	<span> {dataStatistics.withdraw.sum}    </span>        </div>
                                <div>ERCA:      	        <span> {dataStatistics.erca}            </span>        </div>
                            </>
                        } </>
                        : <>
                        <div>Code I:   <span> {dataStatistics.tickets.count}   </span>        </div>
                        <div>Code P:   <span> {dataStatistics.paid.count}      </span>        </div>
                        <div>Code C:   <span> {dataStatistics.cb.count}        </span>        </div>
                        </>
                        }
                    </div>
                    }

                    {isPrintView &&

                    <div className={`modal-box-content ticket-modal-content`}>
                        <span className={'modal-content-img-part'}>
                            {isLogo && countrySettings && countrySettings.logo &&
                                <img className={'label-logo'} src={countrySettings.logo.print} alt={countrySettings.logo.alt}/>
                            }
                        </span>
                        <span>PackageID: {this.calcPackageID(dataStatistics.package_id)}</span>
                        <span className={'after-underline'}>Total Amount: {dataStatistics.amount + ' ' + currency}</span>
                        {!isGame && <span>Total Odds: {fixByRule(dataStatistics.total_odds)}</span>}
                        <span >Total Winning: {dataStatistics.possible_winning + ' ' + currency}</span>
                        {!isGame && dataStatistics.possible_bonus && ([ 'VAMOSETH', 'HABESHAETH', 'DASH'].includes(globalSettings.label.project)
                        ? <span>Multi Bonus: {dataStatistics.possible_bonus + ' ' + currency}</span>
                        : isNumber(dataStatistics.possible_bonus) ? <span>Possible Bonus: {dataStatistics.possible_bonus + ' ' + currency}</span> : null
                        )}

                        {(!isGame && isNumber(dataStatistics.possible_tax)) ? 
                            <span>{globalSettings.label.project === 'PMBETZM' ? `Withholding Tax ${globalSettings && globalSettings.betting && globalSettings.betting.Tax ? globalSettings.betting.Tax + '%' : ''}` : 'Possible Tax'}: {dataStatistics.possible_tax  + ' ' + currency}</span> : null}
                        {!isGame && isNumber(dataStatistics.possible_extra_bonus) ? <span>Winning Bonus: {`${dataStatistics.possible_extra_bonus || 0} ${currency}`}</span> : null}
                        {(isNumber(dataStatistics?.payout_sum) || isNumber(dataStatistics?.total_winning)) && globalSettings.label.project !== 'MAVERIX'?
                            <span>Possible Winning: {isNumber(dataStatistics.payout_sum) ? dataStatistics.payout_sum : dataStatistics.total_winning  + ' ' + currency}</span> : null}
                        {dataStatistics.smartphone &&
                        <div className="quadView" >
                            <span className="ballMain">SMARTPHONE BONUS BALLS<br/>{dataStatistics.smartphone.date}</span>
                            <ol type="A" className="printOl">
                                {dataStatistics.smartphone.bets &&  dataStatistics.smartphone.bets.map((wcup, index)=>(
                                    <li className="ballRb"  key={index}>
                                        {wcup.split(",").map((val,key)=>(
                                            <span className="printBall" key={key}>{val}</span>
                                        ))}
                                    </li>
                                ))}
                            </ol>
                        </div>
                        }
                    </div>

                    }

                    {isToto &&
                    <div className={`modal-box-content ticket-modal-content`}>
                        <span className={'modal-content-img-part'}>
                            {(isLogo && countrySettings && countrySettings.logo) &&
                            <img className={'label-logo'} src={countrySettings.logo.print} alt={countrySettings.logo.alt}/>
                            }
                        </span>
                        <span style={{textAlign: 'center'}}>JACKPOT // {dataStatistics.package_id} {isCopy ? " // COPY " : "" }</span>
                        <div>
                            <div>Date issued: {dataStatistics.place_bet_time || ''}</div>
                            <div className={'after-underline'}>Valid until: {dataStatistics.expiration_date || ''}</div>
                            <div style={{borderBottomWidth:1,borderBottomColor:'#333'}}>
                                <div className={'barcode-part'}>{dataStatistics.packageId && <Barcode fontSize={0} lineColor={'black'} width={2.1} height={35} background={"transparent"} value={dataStatistics.packageId} />}</div>
                                <div style={{textAlign: 'center',width:'100%',fontWeight:'bold'}}>{dataStatistics.packageId ? dataStatistics.packageId : ''}</div>
                                <div className={'toto-ticket-events'}>
                                    {dataStatistics.events.map((betDetail, betIndex)=>(
                                        <div key={betIndex}>
                                            {this.rawEvent(betDetail,(betIndex + 1))}
                                            {betIndex === 11 &&
                                                <div className={'toto-ticket-reserve'}>Reserve Games</div>
                                            }
                                        </div>

                                    ))}
                                </div>
                            </div>
                            <div className={'bold-font-total after-underline'}>Total: ={dataStatistics.total_amount}=</div>
                        </div>
                    </div>
                    }

                    {isBet &&
                    <div className={`modal-box-content ticket-modal-content`}>
                        <span className={'modal-content-img-part'}>
                            {(isLogo && countrySettings && countrySettings.logo) &&
                                <img className={'label-logo'} src={countrySettings.logo.print} alt={countrySettings.logo.alt}/>
                            }
                        </span>
                        <div className={`barcode-part ${globalSettings.label.project === 'BWANABET' ? 'after-underline' : ''}`}>{dataStatistics.package_id && <Barcode fontSize={0} lineColor={'black'} width={2.1} height={45} background={"transparent"} value={dataStatistics.package_id} />}</div>
                        <span className={`${globalSettings.label.project === 'BWANABET' ? 'after-underline' : ''}`} style={{textAlign: 'center', fontWeight: globalSettings.label.project === 'BWANABET' ? 'bold' : ''}}>{dataStatistics.kind ? dataStatistics.kind.toUpperCase() : ''} // {dataStatistics.package_id} {isCopy ? " // COPY " : "" }</span>
                        <div>
                            {(globalSettings && globalSettings.label && globalSettings.label.country === 'ETHIOPIA') &&
                            <>
                                <div>Cashier {userData.employee_info && (userData.employee_info.first_name +' '+ userData.employee_info.last_name)} , {userData.username}</div>
                                <div>{userData.user_address}</div>
                            </>
                            }
                            {(globalSettings && globalSettings.label && ['BWANABET'].includes(globalSettings.label.project)) &&
                                <>
                                    <div>Bet shop: {userData.username}</div>
                                    {userData.employee_info ? 
                                        <div>Cashier: {userData.employee_info && (userData.employee_info.first_name +' '+ userData.employee_info.last_name)}</div> : null}
                                </>
                            }
                            {(globalSettings && globalSettings.label && ['PMBETTZ'].includes(globalSettings.label.project)) &&
                                <>
                                    <div>Bet shop: {userData.kassa_title}</div>
                                    {userData.employee_info ? 
                                        <div>Cashier: {userData.employee_info && (userData.employee_info.first_name +' '+ userData.employee_info.last_name)}</div> : null}
                                </>
                            }
                            <div>Date issued: {dataStatistics.bets[0].place_bet_time || ''}</div>
                            <div className={'after-underline'}>Valid until: {dataStatistics.expiration_date || ''}</div>
                            {dataStatistics.bets.map((betDetail, betIndex)=>(
                                globalSettings.label.project === 'BWANABET' ? 
                                <div key={betIndex}>
                                    <div><span className='event-numbering'>[{++betIndex}]</span> <b>{betDetail.event_short_code}</b> {betDetail.sport_name}:{betDetail.competition_name || ''}</div>
                                    <div>{betDetail.event_start_time ? <>{betDetail.event_start_time.split(' ')[0]} - <b>{betDetail.event_start_time.split(' ')[1]}</b></> : betDetail.place_bet_time}</div>
                                    <div>{isGame ? betDetail.draw : <b>{betDetail.event_name}</b>}</div>
                                    <div className={'ticket-right-left-pull after-underline'}><b>{betDetail.market_name ? betDetail.market_name : ''}: {betDetail.price_name}{isGame && !betDetail.wof_kind ? this.selectionFunc(betDetail.kind,betDetail.event_name) : (betDetail.wof_kind === "Single" ? `Single (${betDetail.event_name})` : betDetail.wof_kind)}</b><span><b>{betDetail.price_rate ? fixByRule(betDetail.price_rate) : ''}</b></span></div>
                                </div>
                                : <div key={betIndex}>
                                    <div>{betDetail.sport_name || ''}: {betDetail.competition_name || ''}</div>
                                    <div>{betDetail.event_start_time ? betDetail.event_start_time : betDetail.place_bet_time} / {isGame ? betDetail.draw : betDetail.event_name}</div>
                                    <div>{betDetail.event_short_code ? betDetail.event_short_code : ''} {betDetail.market_name ? betDetail.market_name : ''}</div>
                                    <div className={'ticket-right-left-pull after-underline'}>{betDetail.price_name} {isGame && !betDetail.wof_kind ? this.selectionFunc(betDetail.kind,betDetail.event_name) : (betDetail.wof_kind === "Single" ? `Single (${betDetail.event_name})` : betDetail.wof_kind)} <span>{betDetail.price_rate ? fixByRule(betDetail.price_rate) : ''} {dataStatistics && dataStatistics.type === 0 && globalSettings.label.project !== 'MAVERIX' ? `=${betDetail.amount}=` : ''} {isGame ? `=${betDetail.amount}=` : ''}  </span></div>
                                </div>
                            ))}

                            {dataStatistics.smartphone &&
                                <div className="quad">
                                    <span className="ballMain">SMARTPHONE BONUS BALLS<br/>{dataStatistics.smartphone.date}</span>
                                    <ol type="A" className="printOl">
                                        {dataStatistics.smartphone.bets &&  dataStatistics.smartphone.bets.map((wcup, index)=>(
                                            <li className="ballRb"  key={index}>
                                                {wcup.split(",").map((val,key)=>(
                                                    <span className="printBall" key={key}>{val}</span>
                                                ))}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            }
                            {this.betSlipInfo(dataStatistics,isGame)}
                        </div>
                        {globalSettings.label.project === 'VAMOSETH' && <div style={{ marginTop: '20px'}}>
                            {signatureTexts.map(item => {
                                return <div key={item} style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div>{item}</div>
                                    <div>______________________</div>
                                </div>
                            })}
                        </div>}
                        {globalSettings.label.project === 'BWANABET' && <div className='footer-text'>
                            <div style={{ padding: '10px'}}><b>MAXIMUM TICKET PAYOUT: K {
                                globalSettings.betting?.gamesBetAmounts[dataStatistics?.kind]?.maxwin || globalSettings.betting.MaxWinSum
                            }</b></div>
                            <div>{dataStatistics?.kind === 'sport' ? bwanabetTicketText : ''}</div>

                        </div>}
                    </div>
                    }
                    <div className="modal-box-footer ticket-modal-footer">
                        <div>
                            {isWebsite ? <button onClick={ticketPrint}>Print</button> : <button onClick={toggleModal}>Close</button>}
                        </div>
                    </div>
                </Modal>}
            </div>
        );
    }
}


const mapStateToProps = ({settingsReducer,authReducer}) => {
    return {
        userData: authReducer.authUser,
        globalSettings:settingsReducer.globalSettings
    };
};

export default withRouter(connect(mapStateToProps)(Index));

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Dropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader} from 'reactstrap';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from '../../constants/ActionTypes';
import {
  switchLanguage,
  toggleCollapsedNav,
  pmUserEmployeeSuccess,
  pmUserEmployee,
  pmUserEmployeeSignOut,
  clearEmployeeMessage
} from '../../actions';
import IntlMessages from 'util/IntlMessages';
import LanguageSwitcher from '../../components/LanguageSwitcher/index';
import Menu from '../../components/TopNav/Menu';
import UserInfoPopup from '../../components/UserInfo/UserInfoPopup';

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchShortCode: '',
      searchLive: '',
      searchEventName: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      modal:false,
      username:null,
      password:null
    };

    this.onLangSwitcherSelect = this.onLangSwitcherSelect.bind(this);
    this.onUserInfoSelect = this.onUserInfoSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleEmployeePassword = this.handleEmployeePassword.bind(this);
    this.handleEmployeeUsername = this.handleEmployeeUsername.bind(this);
    this.employeeLogin = this.employeeLogin.bind(this);
    this.emptySearchInputs = this.emptySearchInputs.bind(this);
    this.updateShortCode = this.updateShortCode.bind(this);
    this.updateLive = this.updateLive.bind(this);
    this.updateEventName = this.updateEventName.bind(this);
    this.getSearchData = this.getSearchData.bind(this);
  }

  // onAppNotificationSelect = () => {
  //   this.setState({
  //     appNotification: !this.state.appNotification
  //   })
  // };

  // onMailNotificationSelect = () => {
  //   this.setState({
  //     mailNotification: !this.state.mailNotification
  //   })
  // };

  onLangSwitcherSelect(event){
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };

  // onSearchBoxSelect = () => {
  //   this.setState({
  //     searchBox: !this.state.searchBox
  //   })
  // };

  // onAppsSelect = () => {
  //   this.setState({
  //     apps: !this.state.apps
  //   })
  // };

  onUserInfoSelect(){
    this.setState({
      userInfo: !this.state.userInfo
    })
  };

  handleRequestClose(){
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  toggleModal(check){
    if (check === 'open'){
      this.props.pmUserEmployeeSuccess(true)
    } else {
      this.props.clearEmployeeMessage();
      this.props.pmUserEmployeeSuccess(false);
    }
  }

  updateShortCode(event, key){
    this.setState({
      searchShortCode: event.target.value,
    });

    if (event && event.key === 'Enter' ){
      this.getSearchData(key)
    }
  };

  updateLive(event, key){
    this.setState({
      searchLive: event.target.value,
    });

    if (event && event.key === 'Enter' ){
      this.getSearchData(key)
    }
  };

  updateEventName(event,key){
    this.setState({
      searchEventName: event.target.value,
    });

    if (event && event.key === 'Enter' ){
      this.getSearchData(key)
    }
  };

  handleEmployeeUsername(event){
    this.setState({username:event.target.value});

    if(event && event.key === 'Enter'){
      this.employeeLogin()
    }
  }

  handleEmployeePassword(event){
    this.setState({password:event.target.value});

    if(event && event.key === 'Enter'){
      this.employeeLogin()
    }
  }

  employeeLogin() {
    let { username, password } = this.state;

    if (!username || !password){
      return false
    }

    this.props.pmUserEmployee({username:username,password:password});

    if (this.props.authUser && this.props.authUser.employee_info?.isActive){
      this.toggleModal(null, this.props.authUser.employee_info)
    }
  };

  emptySearchInputs(keyWord){
    let { searchShortCode, searchLive, searchEventName } = this.state;

    console.log(searchShortCode);

    if(keyWord === 'shortCode'){
      console.log(searchShortCode);
      this.setState({searchShortCode: ''});
    } else if (keyWord === 'live' && searchLive.length) {
      this.setState({searchLive:''});
    } else if (keyWord === 'eventName' && searchEventName.length) {
      this.setState({searchEventName:''});
    }
  };

  getSearchData(key){
    let { searchShortCode, searchLive, searchEventName } = this.state;

    if(key === 'shortCode' && searchShortCode.length && this.props.match.params.query !== searchShortCode){
      this.props.history.push({ pathname: `/app/dashboard/search/code/${searchShortCode}` });
    } else if (key === 'live' && searchLive.length && this.props.match.params.query !== searchLive) {
      this.props.history.push({ pathname: `/app/dashboard/search/live/${searchLive}` });
    } else if (key === 'eventName' && searchEventName.length && this.props.match.params.query !== searchEventName) {
      this.props.history.push({ pathname: `/app/dashboard/search/event/${searchEventName}` });
    }
  };

  render() {
    const {drawerType, locale, navigationStyle, horizontalNavPosition, countrySettings, balanceLimit,authUser,globalSettings} = this.props;
    const {searchShortCode, searchLive, searchEventName, langSwitcher, userInfo} = this.state;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ?
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
              <span className="jr-menu-icon">
                <span className="menu-icon"/>
              </span>
            </div>
            :
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon"/>
            </IconButton>
          }

          <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard">
            {countrySettings.logo ? <img src={countrySettings.logo.img} alt={countrySettings.logo.alt} title={countrySettings.logo.title} /> : <div />}
          </Link>

          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
          <Menu/>}


          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <div style={headLimitStyle}>
                <span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{authUser && authUser.user_type}: {authUser && authUser.username}</span>
              </div>
            </li>
            <li className="list-inline-item">
              <div style={
                authUser && authUser.user_type === 'FRANCHISE' ?
                  { ...balanceLimitStyle, color: globalSettings.label && ['PMBETTZ'].includes(globalSettings.label.project) ? '#38434b' : '#fad149'} :  
                  (globalSettings.label && ['PMBETTZ','HABESHAETH'].includes(globalSettings.label.project) ? balanceLimitStyle2 : balanceLimitStyle1)
                }>
                <span style={ {textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}} title={this.props.balanceLimit.balance}><IntlMessages id={'Balance'}/>: {this.props.balanceLimit.balance}</span>
                <span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}><IntlMessages id={'Limit'}/>: {(authUser && authUser.user_type === 'SHOP') ? <span>&infin;</span>: this.props.balanceLimit.limit}</span>
                {(authUser && authUser.user_type === 'FRANCHISE' && authUser.user_country !== 'ETHIOPIA') &&
                <>
                <span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>W.TAX: {this.props.balanceLimit.deduction}</span>
                <span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>JPR: {this.props.balanceLimit.jpr}</span>
                </>
                }
              </div>
            </li>
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={langSwitcher}
                toggle={this.onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50" modifiers={{
                  setMaxHeight:{
                    enabled: true,
                    order: 890,
                    fn : (data) => { return {...data, styles: {...data.styles,overflow: 'hidden', maxHeight: 180}}}
                  }}}
                >
                  <LanguageSwitcher switchLanguage={this.props.switchLanguage} handleRequestClose={this.handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>

            </li>

            {navigationStyle === HORIZONTAL_NAVIGATION &&
            <li className="list-inline-item user-nav">
              <Dropdown
                  cssModule={{
                    overflow:'hidden'
                  }}
                className="quick-menu"
                isOpen={userInfo}
                toggle={this.onUserInfoSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-account zmdi-hc-1x" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfoPopup/>
                </DropdownMenu>
              </Dropdown>
            </li>
            }
          </ul>

          {/*<div className="ellipse-shape" />*/}

        </Toolbar>

        <div className={'header__search-part'} >
          <div className={'header__search-part-inputs-only'}>
            {(globalSettings.label && !['JEGINA'].includes(globalSettings.label.project)) &&
              <div className={'header__search-part-inputs'}>
                <input onKeyPress={(event) => this.updateShortCode(event,'shortCode')} placeholder="Event Short Code" onChange={this.updateShortCode} onFocus={() => this.emptySearchInputs('shortCode')} value={searchShortCode}/>
                <button onClick={() => this.getSearchData('shortCode')} className="search-icon">
                  <i className="zmdi zmdi-search zmdi-hc-lg"/>
                </button>
              </div>
            }
            {(globalSettings.label && !['VAMOSETH', 'HABESHAETH', 'JEGINA'].includes(globalSettings.label.project)) &&
              <div className={'header__search-part-inputs'}>
                <input onKeyPress={(event) => this.updateLive(event,'live')} placeholder="Live" onChange={this.updateLive} onFocus={() => this.emptySearchInputs('live')} value={searchLive}/>
                <button onClick={() => this.getSearchData('live')} className="search-icon">
                  <i className="zmdi zmdi-search zmdi-hc-lg"/>
                </button>
              </div>
            }
            {(globalSettings.label && !['JEGINA'].includes(globalSettings.label.project)) &&
              <div className={'header__search-part-inputs'}>
                <input onKeyPress={(event) =>this.updateEventName(event,'eventName')} placeholder="Event Name" onChange={this.updateEventName} onFocus={() => this.emptySearchInputs('eventName')} value={searchEventName}/>
                <button onClick={() => this.getSearchData('eventName')} className="search-icon">
                  <i className="zmdi zmdi-search zmdi-hc-lg"/>
                </button>
              </div>
            }
          </div>
          <div className={'header__search-part-employee'}>
              {authUser && authUser.employee_info?.isActive  ?
                  <div className="header__search-part-employee-info">
                    <div title={`${authUser.employee_info.first_name} ${authUser.employee_info.last_name}`}>{(balanceLimit && ( globalSettings.label.project === 'PMBETKE' || globalSettings.label.project === 'PMBETTZ' )) && <span>{balanceLimit.points/10} :</span>} {authUser.employee_info.first_name} {authUser.employee_info.last_name}</div>
                    {(globalSettings.label && !globalSettings.label.CBTwoFactorAuth) &&
                      <span onClick={this.props.pmUserEmployeeSignOut}><IntlMessages id="Employee Sign Out"/><i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/></span>
                    }
                  </div>
                  :
                  <span className={'header__search-part-employee-sign-in'} onClick={() => this.toggleModal('open')}>
                    <IntlMessages id="EMPLOYEE LOGIN"/>
                    <i className="zmdi zmdi-pin-account zmdi-hc-fw zmdi-hc-2x mr-1" />
                  </span>
              }
          </div>
        </div>


        <Modal className="modal-box employee-modal" fade={false} isOpen={this.props.employeeUserSuccess} toggle={this.toggleModal} style={{zIndex: 2600}}>
          <ModalHeader className="modal-box-header bg-primary text-white">
            Employee Login
            <i onClick={this.toggleModal} className="zmdi zmdi-close text-white"/>
          </ModalHeader>
          <div className="modal-box-content employee-modal-content">
              <p>{this.props.employeeMessage && this.props.employeeMessage.length > 0 ? this.props.employeeMessage : '' }</p>
            <input onKeyPress={this.handleEmployeeUsername} onChange={this.handleEmployeeUsername} type="text" placeholder={'Username'}/>
            <input onKeyPress={this.handleEmployeePassword} onChange={this.handleEmployeePassword} type="password" placeholder={'Password'}/>
          </div>
          <div className="modal-box-footer employee-modal-footer">
            <button onClick={this.employeeLogin}>
              Login
            </button>
          </div>
        </Modal>
      </AppBar>
    );
  }
}

const balanceLimitStyle = {
  width: '460px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '20px',
  fontWeight: 'bold',
  color: '#fad149',
  letterSpacing: '1px'
};
const balanceLimitStyle1 = {
  width: '240px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '20px',
  fontWeight: 'bold',
  color: '#fad149',
  letterSpacing: '1px'
};
const balanceLimitStyle2 = {
  width: '240px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '20px',
  fontWeight: 'bold',
  color: '#38434b',
  letterSpacing: '1px'
};

const headLimitStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '20px',
  fontWeight: 'bold',
  color: '#05cd00',
  letterSpacing: '1px'
};

const mapStateToProps = ({settingsReducer, searchReducer, balanceLimitReducer, authReducer}) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition, countrySettings ,globalSettings } = settingsReducer;
  const { searchData } = searchReducer;
  const { balanceLimit } = balanceLimitReducer;
  const { authUser, employeeUserSuccess,employeeMessage } = authReducer;
  return { drawerType, locale, navigationStyle, horizontalNavPosition, countrySettings, searchData, balanceLimit, authUser, employeeUserSuccess, employeeMessage,globalSettings }
};

export default withRouter(connect(mapStateToProps, {
  toggleCollapsedNav,
  switchLanguage,
  pmUserEmployee,
  pmUserEmployeeSuccess,
  pmUserEmployeeSignOut,
  clearEmployeeMessage
})(Header));
